/* eslint-disable no-shadow */
import { toast } from 'react-toastify';
import {
  GET_CRM_USERS,
  GET_UPLINE_USERS,
  GET_MANAGERS,
  GET_HIERARCHY_ORDER,
  GET_CRM_USER,
  ADD_CRM_USER,
  EDIT_CRM_USER,
  DELETE_CRM_USER,
  TOGGLE_CRM_USER_STATE,
  GET_CRM_USER_INFO,
  ERROR_CRM_USER_STATE,
  CURRENT_USER_PERMISSIONS,
  CHANGE_PASSWORD,
  GET_REG_UPINE_USERS,
  SEND_EMAIL,
  SEND_EMAIL_ERROR,
  CREATE_CRM_FILTER,
  UPDATE_CRM_FILTER,
  DELETE_CRM_FILTER,
  EXPORT_FILTERED_DATA_TO_FILE,
  GET_CRM_USERS_BY_QUERY,
  GET_DESK_IBS_USERS,
} from './crmUserTypes';
import { apiHelper } from '../apiHelper';
import { getPermission } from '../../config/helpers';
import { createUserSentEmailHistory, getUserSentEmailHistory } from '../userSentEmailHistory/userSentEmailHistoryActions';

export const getDesksIbs = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/admin/desks-ibs');
    if (res?.data) {
      const { data } = res;
      await dispatch({
        type: GET_DESK_IBS_USERS,
        payload: data.desks,
      });
    } else {
      await dispatch({
        type: GET_DESK_IBS_USERS,
        payload: [],
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getCRMUsers = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/admin', '');
    if (res?.data && res?.data?.crmUsers) {
      const { data } = res;
      await dispatch({
        type: GET_CRM_USERS,
        payload: data?.crmUsers,
      });
    } else {
      await dispatch({
        type: GET_CRM_USERS,
        payload: [],
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getSubCRMUsers = (roleId, userId) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/admin/sub-crm-user', { role_id: roleId, user_id: userId });
    if (res?.data && res?.data?.crmUsers) {
      const { data } = res;

      await dispatch({
        type: GET_CRM_USERS,
        payload: data?.crmUsers,
      });
    } else {
      await dispatch({
        type: GET_CRM_USERS,
        payload: [],
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getUplineUsers = (userId, roleId) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/admin/upline/${userId}/${roleId}`, '');
    if (res?.data && res?.data?.uplineUsers) {
      const { data } = res;

      await dispatch({
        type: GET_UPLINE_USERS,
        payload: data?.uplineUsers,
      });
    } else {
      await dispatch({
        type: GET_UPLINE_USERS,
        payload: [],
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getRegUplineUsers = (roleId) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/admin/reg-upline/${roleId}`, '');
    if (res?.data && res?.data?.uplineUsers) {
      const { data } = res;

      await dispatch({
        type: GET_REG_UPINE_USERS,
        payload: data?.uplineUsers,
      });
    } else {
      await dispatch({
        type: GET_REG_UPINE_USERS,
        payload: [],
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getManagers = () => async (dispatch) => {
  try {
    const res = await apiHelper('get', '/api/admin/managers', '');
    if (res?.data) {
      const { data } = res;
      dispatch({
        type: GET_MANAGERS,
        payload: data?.managers,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getHierarchyOrder = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/admin/hierarchy-order/${id}`, '');
    if (res?.data && res?.data?.hierarchyOrder) {
      const { data } = res;

      await dispatch({
        type: GET_HIERARCHY_ORDER,
        payload: data?.hierarchyOrder,
      });
    } else {
      await dispatch({
        type: GET_HIERARCHY_ORDER,
        payload: [],
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getCRMUser = (id, setPermissions = false) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/admin/${id}`, '');
    if (res?.data && res?.data?.crmUser) {
      const { data } = res;
      if (!setPermissions) {
        await dispatch({
          type: GET_CRM_USER,
          payload: data?.crmUser,
        });
      }

      if (setPermissions) {
        if (data?.crmUser?.permissions?.length > 0) {
          const userPerms = await getPermission(data?.crmUser?.permissions);
          await dispatch({
            type: CURRENT_USER_PERMISSIONS,
            payload: userPerms,
          });
        } else {
          await dispatch({
            type: CURRENT_USER_PERMISSIONS,
            payload: [],
          });
        }
      }
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const getCRMUserInfo = (id, setPermissions = false) => async (dispatch) => {
  try {
    const res = await apiHelper('get', `/api/admin/${id}`, '');
    if (res?.data && res?.data?.crmUser) {
      const { data } = res;
      if (!setPermissions) {
        await dispatch({
          type: GET_CRM_USER_INFO,
          payload: data?.crmUser,
        });
      }

      if (setPermissions) {
        if (data?.crmUser?.permissions?.length > 0) {
          const userPerms = await getPermission(data?.crmUser?.permissions);
          await dispatch({
            type: CURRENT_USER_PERMISSIONS,
            payload: userPerms,
          });
        } else {
          await dispatch({
            type: CURRENT_USER_PERMISSIONS,
            payload: [],
          });
        }
      }
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const addCRMUser = (data) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/admin/add', data);

    if (res?.data && res?.data?.newUserRecord) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: ADD_CRM_USER,
        payload: data?.newUserRecord,
      });
    } else {
      await dispatch({
        type: ERROR_CRM_USER_STATE,
      });
    }
  } catch (error) {
    // console.log(error.message);
    toast.error(error.message);
  }
};

export const editCRMUser = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/admin/${id}`, data);
    if (res?.data && res?.data?.user) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: EDIT_CRM_USER,
        payload: data?.user,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const deleteCRMUser = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/admin/${id}`, '');
    if (res?.data && res?.data?.user) {
      const { data } = res;
      toast.success(data?.message);
      await dispatch({
        type: DELETE_CRM_USER,
        payload: data?.user?._id,
      });
    }
  } catch (error) {
    // console.log(error.response.message);
    toast.error(error.response.message);
  }
};

export const changePassword = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/admin/change-password/${id}`, data);
    if (res?.data) {
      const { data } = res;
      toast.success(data.message);
      dispatch({
        type: CHANGE_PASSWORD,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const updateCRMUserState = () => async (dispatch) => {
  try {
    await dispatch({
      type: TOGGLE_CRM_USER_STATE,
    });
  } catch (error) {
    // console.log(error.response.message);
  }
};

export const sendEmail = (emailData, crmUserName) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/admin/send-email', emailData);
    
    if (res?.data?.success) {
      await dispatch({
        type: SEND_EMAIL,
      });
      toast.success('Email sent successfully');

      await dispatch(createUserSentEmailHistory({
        user: emailData.userId,
        agent: emailData.crmUserId,
        agentName: crmUserName,
      }));
    } else {
      dispatch({
        type: SEND_EMAIL_ERROR,
        payload: res?.data?.message || 'An error occurred while sending the email',
      });
      toast.error(res?.data?.message || 'An error occurred while sending the email');
    }
  } catch (error) {
    dispatch({
      type: SEND_EMAIL_ERROR,
      payload: error.response?.data?.message || 'An error occurred while sending the email',
    });
    toast.error(error.response?.data?.message || 'An error occurred while sending the email');
  }
};

export const createCRMFilter = (data) => async (dispatch) => {
  let res;

  try {
    res = await apiHelper('post', '/api/filter', data);

    if (res && res.data) {
      dispatch({
        type: CREATE_CRM_FILTER,
        payload: res.data.filter,
      });
      toast.success('Filter was created!');
    }
  } catch (error) {
    toast.error(error);
  }

  return res;
};

export const deleteCRMFilter = (id) => async (dispatch) => {
  try {
    const res = await apiHelper('delete', `/api/filter/${id}`, '');

    if (res && res.data) {
      dispatch({
        type: DELETE_CRM_FILTER,
        payload: res.data.filter,
      });
      toast.success('Filter was deleted!');
    }
  } catch (error) {
    toast.error(error);
  }
};

export const updateCRMFilter = (id, data) => async (dispatch) => {
  try {
    const res = await apiHelper('put', `/api/filter/${id}`, data);

    if (res && res.data) {
      dispatch({
        type: UPDATE_CRM_FILTER,
        payload: res.data.filter,
      });
      toast.success('Filter was updated!');
    }
  } catch (error) {
    toast.error(error);
  }
};
export const exportFilteredDataToFile = (filteredItems, columns, fileType, selectedUsersIds = []) => async (dispatch) => {
  try {
    const res = await apiHelper('post', '/api/admin/export', {
      filteredItems, columns, fileType, selectedUsersIds,
    });
    if (res.data && res.data.fileUrl) {
      window.open(res.data.fileUrl);
    } else {
      toast.error('File was not created. Please, contact our support team.', {
        autoClose: 1000,
      });
    }
  } catch (error) {
    toast.error(error.response.message, {
      autoClose: 1000,
    });
  } finally {
    dispatch({
      type: EXPORT_FILTERED_DATA_TO_FILE,
      payload: true,
    });
  }
};

export const updateExportDataState = () => async (dispatch) => {
  try {
    dispatch({
      type: EXPORT_FILTERED_DATA_TO_FILE,
      payload: false,
    });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const editCRMUsers = async (data) => {
  try {
    const res = await apiHelper('put', '/api/admin/update-multiple', data);
    if (res?.data) {
      toast.success(res.data.message);
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};

export const getCRMUsersByQuery = ({
  page, limit, query = {},
}) => async (dispatch) => {
  try {
    let url = `?page=${page}&limit=${limit}`;

    Object.entries(query).forEach(([key, value]) => { 
      url += `&${key}=${value}`; 
    });

    const res = await apiHelper('get', `/api/admin/crm-users-table-data/${url}`);

    if (res.status === 200) {
      await dispatch({
        type: GET_CRM_USERS_BY_QUERY,
        payload: res.data.crmUsers,
      });
    }
  } catch (error) {
    toast.error(error.response.message);
  }
};
