import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import {
  faClone, faComment, faEnvelope, faPhone, faExternalLink, faCircleInfo, faTimes, faMessage,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import { editUser } from '../../redux/users/userActions';
import { getAdminComments } from '../../redux/adminComment/adminCommentActions';
import { ModalFilters } from '../../components';
import { RangeModalDebounceFilter } from '../../pages/Transactions/modalFilters';
import {
  omitColumn, formatDate, setTransactionActionColumn, dateOptions, 
} from '../helper';
import { getRateInUsdt } from '../../helpers/getRateInUSD';

import styles from './styles.module.css';


export const ExternalTransactionsSchema = (
  setTransactionIdFilter,
  permissionName,
  currencies,
  transactionIdFilter,
  uidFilter,
  setUidFilter,
  nameFilter,
  setNameFilter,
  emailFilter, 
  setEmailFilter,
  setAssetFilters,
  assetFilters,
  amountRange,
  setAmountRange,
  handleAdditionalInfoModalToggle,
  handleAdditionalCommentModalToggle,
  setManagerFilters,
  managerFilters,
  realFilter,
  setRealFilter,
  statusFilter,
  setStatusFilter,
  isAdditionalInfoModalShown,
  isAdditionalCommentModalShown,
  setIsAdditionalInfoModalShown,
  setIsAdditionalCommentModalShown,
  resolveCurrentDepositTransaction,
  handleRevertTransaction,
  assignToShow, 
  depositsFilters,
  setDepositsFilters,
  columnConfig,
  timeInitiatedFilter,
  setTimeInitiatedFilter,
  updateIsReal,
  handleDeleteTransaction,
  isDeleted,
  setDebounceAmountRange,
  inputFieldError, 
  setInputFieldError,
  brandsFilters,
  setBrandsFilters,
) => {
  const dispatch = useDispatch();
  const [showComments, setShowComments] = useState(0);

  const adminComments = useSelector((state) => state?.adminComment?.adminComments);
  const [commentDate, setCommentDate] = useState(new Date());
  const currencyRates = useSelector((state) => state.currencyRates);
  const brands = useSelector((state) => state.brands);

  const copyReferral = () => {
    toast.success('Successfully copied!', {
      autoClose: 1000,
    });
  };

  function JSONparser(inputString) {
    if (!inputString) {
      console.warn('Empty string received for JSON parsing');
      return null;
    }
    try {
      const parsed = JSON.parse(inputString);
      return parsed;
    } catch (e) {
      console.error('JSON parse error:', e);
      return null;
    }
  }

  const handleShowComment = (userId, rowId) => {
    dispatch(getAdminComments(userId));
    if (adminComments.length > 0) {
      setCommentDate(new Date(adminComments[adminComments.length - 1].createdAt));
    } 

    setShowComments(rowId);
  };

  const handleChangeAssignTo = async (e, id) => {
    try {
      const data = {
        assignedTo: e.target.value,
      };
      await dispatch(editUser(id, data));
    } catch (error) {
      toast.error(error);
    }
  };
  
  const handleValidationInputs = ({ target: { value } }, nameField) => {
    const errorMessage = 'Must be more than 3 symbols';
    setInputFieldError((prev) => ((value.length < 3 && value.length > 0) ? { ...prev, errorMessage, [nameField]: true } : { ...prev, errorMessage: '', [nameField]: false }));
    if (nameField === 'userIdIsNotValid') setUidFilter(value);
    if (nameField === 'fullNameIsNotValid') setNameFilter(value);
    if (nameField === 'emailIsNotValid') setEmailFilter(value);
  };
  
  const isUserCanSeeClientCard = permissionName && permissionName.includes('clients');
  const isUserCanSeeLeadsCard = permissionName && permissionName.includes('leads');
  const isUserCanAssignToAgent = permissionName && permissionName.includes('assign_to_agent');

  const columns = [
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des p-2 pl-0 ${styles.wrapperInput}`}>
          Transaction ID
          <input
            className="leads-search-bar-filter"
            style={inputFieldError.transactionIdIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Transaction ID"
            value={transactionIdFilter || ''}
            onChange={({ target: { value } }) => setTransactionIdFilter(value)}
          />
          <span style={{ height: '20px' }} />
        </div>),
      width: '200px',
      omit: omitColumn(columnConfig, 'Transaction ID'), 
      cell: ({ customId }) => (
        <CopyToClipboard text={customId}>
          <span>      
            {customId}
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="ms-2" />
          </span>
        </CopyToClipboard>
      ),
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des p-2 pl-0 ${styles.wrapperInput}`}>
          Client ID
          <input
            className="leads-search-bar-filter"
            style={inputFieldError.userIdIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Client ID"
            value={uidFilter || ''}
            onChange={(e) => handleValidationInputs(e, 'userIdIsNotValid')}
          />
          <span style={{ height: '20px' }}>
            {inputFieldError.userIdIsNotValid && (
            <p className={styles.errorInput}>
              {inputFieldError.errorMessage}
            </p>
            )}
          </span> 
        </div>
      ),
      width: '200px',
      omit: omitColumn(columnConfig, 'Client ID'), 
      cell: ({ user }) => (
        <CopyToClipboard text={user?.customId}>
          <span>
            {user?.customId}
            <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="ms-2" />
          </span>
        </CopyToClipboard>
      ),
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des p-2 pl-0 ${styles.wrapperInput}`}>
          Client
          <input
            className="leads-search-bar-filter"
            style={inputFieldError.fullNameIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            type="text"
            placeholder="Search"
            autoComplete="off"
            name="Client"
            value={nameFilter || ''}
            onChange={(e) => handleValidationInputs(e, 'fullNameIsNotValid')}
          />
          <span style={{ height: '20px' }}>
            {inputFieldError.fullNameIsNotValid && (
            <p className={styles.errorInput}>
              {inputFieldError.errorMessage}
            </p>
            )}
          </span> 
        </div>
      ),
      omit: omitColumn(columnConfig, 'Client'), 
      cell: ({ _id, user }) => (
        <div style={{
          width: '100%',
          paddingLeft: '30px',
          display: 'flex',
          gap: '3px',
          position: 'relative',
          justifyContent: 'flex-start',
        }}
        >
          {(user.clientType === 1 && isUserCanSeeLeadsCard) || (user.clientType === 2 && isUserCanSeeClientCard)
            ? (
              <Link to={`/user-detail/${user._id}`}>
                <span data-tip={`${user.firstName} ${user.lastName}`} className="full-name-text">
                  {`${user.firstName} ${user.lastName}`}
                </span>
              </Link>
            ) : (
              <span data-tip={`${user.firstName} ${user.lastName}`} className="full-name-text">
                {`${user.firstName} ${user.lastName}`}
              </span>
            )}
          <ReactTooltip />
        </div>
      ),
      width: '250px',
    },
    {
      name: (
        <div className={`d-flex align-items-start flex-column search-input-des p-2 pl-0 ${styles.wrapperInput}`}>
          Email
          <input
            className="leads-search-bar-filter"
            style={inputFieldError.emailIsNotValid ? { border: '1px solid red' } : { border: 'none' }}
            type="text"
            placeholder="Search"
            name="Email"
            autoComplete="off"
            value={emailFilter || ''}
            onChange={(e) => handleValidationInputs(e, 'emailIsNotValid')}
          />
          <span style={{ height: '20px' }}>
            {inputFieldError.emailIsNotValid && (
            <p className={styles.errorInput}>
              {inputFieldError.errorMessage}
            </p>
            )}
          </span> 
        </div>
      ),
      omit: omitColumn(columnConfig, 'Email'), 
      width: '250px',
      cell: ({ user }) => (
        <div style={{ width: '250px', paddingLeft: '40px', marginRight: '10px' }}>
          <CopyToClipboard text={user.email}>
            <span style={{ margin: '5px' }} data-tip={user.email}>
              <FontAwesomeIcon icon={faClone} onClick={() => copyReferral()} className="me-2" />
              {user.email}
            </span>
          </CopyToClipboard>
        </div>
      ),
      sortable: false,
    },
    {
      name: (
        <ModalFilters 
          data={assignToShow} 
          filters={managerFilters} 
          setFilters={setManagerFilters} 
          tabName="Assigned To" 
          searchParams={{ id: '_id', params: ['firstName', 'lastName'], optionsName: ['firstName', 'lastName'] }}
          usersFilters={depositsFilters}
          storageName="Assigned To"
          setUserFilters={setDepositsFilters}
        />
      ),
      width: '200px',
      omit: omitColumn(columnConfig, 'Assigned To'), 
      cell: ({ user, userId }) => {
        const assignedToAgent = user?.assignedTo ? user?.assignedTo : '';
        return (
          assignedToAgent
            ? (
              isUserCanAssignToAgent ? (
                <>
                  <select 
                    className="form-control user-status-select leads-status-select"
                    name="type"
                    defaultValue={assignedToAgent?._id}
                    style={{ color: assignedToAgent?.color ? assignedToAgent?.color : '#fff' }}
                    onChange={(e) => handleChangeAssignTo(e, userId)}
                  >
                    <option value="" style={{ color: '#fff' }} color="#fff">Select Status</option>
                    <option value={assignedToAgent._id} style={{ color: '#fff' }} color="#fff">{`${assignedToAgent.firstName} ${assignedToAgent.lastName}`}</option>
                    {assignToShow?.length > 0 && assignToShow.map((item) => {
                      if (item._id !== assignedToAgent._id) {
                        return (
                          <option key={item._id} value={item._id}>{`${item.firstName} ${item.lastName}`}</option>
                        ); 
                      }
                      return null;
                    }) }
                  </select>
                  <ReactTooltip />
                </>
              ) : (
                <div>{`${assignedToAgent.firstName} ${assignedToAgent.lastName}`}</div>
              )
            ) : '-'
        );
      },
    },
    {
      name: (
        <RangeModalDebounceFilter 
          columnName="Amount"
          value={amountRange ?? [0, 1000000]}
          setValue={setAmountRange}
          setFilters={setDebounceAmountRange}
          filters={{}}
          filedName="amount"
          debounceAmountRange
        />
      ),
      minWidth: '160px',
      omit: omitColumn(columnConfig, 'Amount'), 
      selector: ({ amount }) => amount,
    },
    {
      name: (
        <ModalFilters 
          data={currencies} 
          filters={assetFilters} 
          setFilters={setAssetFilters} 
          tabName="Currency" 
          searchParams={{ id: '_id', params: ['name', '_id', 'symbol'], optionsName: ['symbol'] }}
          usersFilters={depositsFilters}
          setUserFilters={setDepositsFilters}
        />
      ),
      omit: omitColumn(columnConfig, 'Currency'), 
      minWidth: '160px',
      selector: ({ currency }) => currency,
    },
    {
      name: (
        <ModalFilters 
          data={brands} 
          filters={brandsFilters} 
          setFilters={setBrandsFilters} 
          tabName="Brand" 
          searchParams={{ id: '_id', params: ['name', '_id'], optionsName: ['name'] }}
          usersFilters={depositsFilters}
          setUserFilters={setDepositsFilters}
        />
      ),
      omit: omitColumn(columnConfig, 'Brand'), 
      minWidth: '160px',
      selector: ({ brand }) => brand?.name ?? '',
    },
    {
      name: 'In USD',
      selector: (row) => parseFloat(row?.amount).toFixed(2),
      width: '180px',
      omit: omitColumn(columnConfig, 'In USD'), 
      cell: ({ currency, amount }) => { 
        const usdtValue = getRateInUsdt(currency, amount, currencyRates);
        return usdtValue ? usdtValue?.toFixed(2) : '-';
      },
    },
    {
      name: (
        <ModalFilters 
          data={[{ name: 'Pending', _id: '0' }, { name: 'Completed', _id: '1' }, { name: 'Declined', _id: '2' }]}
          filters={statusFilter} 
          setFilters={setStatusFilter} 
          tabName="Status" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={depositsFilters}
          setUserFilters={setDepositsFilters}
        />
      ),
      omit: omitColumn(columnConfig, 'Status'), 
      selector: ({
        _id, isResolved, additionalInfo, comment,
      }) => {
        const additionalInfoObj = JSONparser(additionalInfo);
        return (
          <>
            {isResolved === 0 ? (
              <span className="badge rounded-pill bg-warning">Pending</span>
            ) : isResolved === 1 ? (
              <span className="badge rounded-pill bg-success">Completed</span>
            ) : (
              <span className="badge rounded-pill bg-danger">Declined</span>
            )}
      
            <FontAwesomeIcon
              icon={faCircleInfo}
              className="ms-2"
              size="lg"
              color="#6E7F95"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleAdditionalInfoModalToggle(_id);
              }}
            />
      
            {isAdditionalInfoModalShown === _id ? (
              <div className="depositsAdditionalInfoModal">
                <h5>Additional Info</h5>
                <FontAwesomeIcon
                  icon={faTimes}
                  size="lg"
                  color="#6E7F95"
                  onClick={() => setIsAdditionalInfoModalShown('')}
                />
                {additionalInfoObj ? (
                  Object.hasOwnProperty.call(additionalInfoObj, 'name') ? (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                      <span>
                        Name:
                        {additionalInfoObj.name}
                      </span>
                      <span>
                        Card:
                        {additionalInfoObj.number}
                      </span>
                      <span>
                        CVC:
                        {additionalInfoObj.cvc.value}
                      </span>
                      <span>
                        Expire Date:
                        {additionalInfoObj.expiry}
                      </span>
                    </div>
                  ) : (
                    additionalInfo
                  )
                ) : (
                  'No Info'
                )}
              </div>
            ) : null}
      
            <FontAwesomeIcon
              icon={faComment}
              className="ms-2"
              size="lg"
              color="#6E7F95"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                handleAdditionalCommentModalToggle(_id);
              }}
            />
      
            {isAdditionalCommentModalShown === _id && (
            <div className="depositsAdditionalCommentModal">
              <h5>Comment</h5>
              <FontAwesomeIcon
                icon={faTimes}
                size="lg"
                color="#6E7F95"
                onClick={() => setIsAdditionalCommentModalShown('')}
              />
              <div className="depositsAdditionalCommentModalIn">
                {comment && comment.length ? comment : 'No comments'}
              </div>
            </div>
            )}
          </>
        );
      },
    },
    {
      name: (
        <ModalFilters 
          data={dateOptions} 
          filters={timeInitiatedFilter} 
          setFilters={setTimeInitiatedFilter} 
          tabName="Time Initiated" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={depositsFilters}
          setUserFilters={setDepositsFilters}
          isDateOptions
        />
      ),
      selector: ({ createdAt }) => (createdAt ? formatDate(new Date(createdAt)) : ''),
      omit: omitColumn(columnConfig, 'Time Initiated'),
      minWidth: '230px',
    },
    {
      name: 'Transaction Time',
      omit: omitColumn(columnConfig, 'Transaction Time'), 
      selector: ({ procesedTime }) => (procesedTime ? formatDate(new Date(procesedTime)) : '-'),
      sortable: false,
      minWidth: '200px',
    },
    {
      name: (
        <ModalFilters 
          data={[{ name: 'Fake', _id: false }, { name: 'Real', _id: true }]}
          filters={realFilter} 
          setFilters={setRealFilter} 
          tabName="Real/Fake" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={depositsFilters}
          setUserFilters={setDepositsFilters}
        />
      ),
      minWidth: '160px',
      omit: omitColumn(columnConfig, 'Real/Fake'), 
      selector: ({ isReal }) => (isReal !== undefined ? (isReal === true ? <span className="clr-green">Real</span> : <span className="clr-red">Fake</span>) : '-'),
    },
    {
      name: 'Action(s)',
      minWidth: '300px',
      omit: omitColumn(columnConfig, 'Actions'), 
      cell: (row) => setTransactionActionColumn(
        row, 
        permissionName,
        resolveCurrentDepositTransaction,
        updateIsReal,
        handleRevertTransaction,
        handleDeleteTransaction,
        isDeleted,
        dispatch,
      ),
    },
  ];
  return columns;
};

