import React, { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { MultiSelect } from 'react-multi-select-component';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from '../../../context';
import { updateOtherPsp, addOtherPsp } from '../../../redux/pspOthers/pspOthersActions';
import { selectAllCountries } from '../../../redux/countries/countriesSelectors';

export function OtherPspModal({ row, type = 'add' }) {
  const dispatch = useDispatch();
  const { hideModal } = useContext(ModalContext);
  const isCreating = type === 'add';
  const [formData, setFormData] = useState(
    {
      label: row.label,
      url: row.url,
      status: row.status,
      brandIds: [],
      includedCountries: [],
      excludedCountries: [],
      image: null,
    }, 
  );
  const [preview, setPreview] = useState(`${process.env.REACT_APP_SERVER_URL}/images/${row.imageUrl}`);
  const countries = useSelector(selectAllCountries);
  const brands = useSelector((state) => state.brands);

  const brandsOptions = brands.map(({ _id, name }) => ({ label: name, value: _id, key: _id }));
  const countriesOptions = countries.map(({ _id, nicename }) => ({ label: nicename, value: _id, key: _id }));

  useEffect(() => {
    if (isCreating) {
      setFormData((prevData) => ({
        ...prevData,
        brandIds: [],
      }));

      return;
    }

    if (brands.length) {
      const brandIds = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const id of row.brandIds) {
        const brand = brands.find(({ _id }) => _id === id);

        if (brand) {
          brandIds.push({
            key: brand._id,
            value: brand._id,
            label: brand.name,
          });
        }
      }

      setFormData((prevData) => ({
        ...prevData,
        brandIds,
      }));
    }
  }, [isCreating, brands]);

  useEffect(() => {
    if (isCreating) {
      setFormData((prevData) => ({
        ...prevData,
        includedCountries: [],
        excludedCountries: [],
      }));

      return;
    }

    if (countries.length) {
      const includedCountries = [];
      const excludedCountries = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const country of countries) {
        if (row.includedCountries.includes(country._id)) {
          includedCountries.push({
            key: country._id,
            value: country._id,
            label: country.nicename,
          });
        }

        if (row.excludedCountries.includes(country._id)) {
          excludedCountries.push({
            key: country._id,
            value: country._id,
            label: country.nicename,
          });
        }
      }

      setFormData((prevData) => ({
        ...prevData,
        includedCountries,
        excludedCountries,
      }));
    }
  }, [isCreating, countries]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formDataToSend = new FormData();
    try {
      const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;

      if (!urlPattern.test(formData.url)) {
        throw new Error('URL must be a valid link');
      }
      const brandIds = formData.brandIds.map(({ value }) => value);
      const includedCountries = formData.includedCountries.map(({ value }) => value);
      const excludedCountries = formData.excludedCountries.map(({ value }) => value);

      formDataToSend.append('label', formData.label);
      formDataToSend.append('brandIds', JSON.stringify(brandIds));
      formDataToSend.append('includedCountries', JSON.stringify(includedCountries));
      formDataToSend.append('excludedCountries', JSON.stringify(excludedCountries));
      formDataToSend.append('url', formData.url);
      formDataToSend.append('image', formData.image);

      if (!isCreating) {
        formDataToSend.append('status', formData.status);
      }

      if (isCreating) {
        await dispatch(addOtherPsp(formDataToSend)); 
      } else {
        await dispatch(updateOtherPsp(row._id, formDataToSend));
      }

      setFormData({
        label: '',
        url: '',
        image: null,
        brandIds: [],
        includedCountries: [],
        excludedCountries: [],
      });
      hideModal();
    } catch (error) {
      toast.error(error.message);
      hideModal();
    }
  };

  const handleInputChange = (event) => {
    const {
      name, value, type, files, 
    } = event.target;
  
    if (type === 'file') {
      const file = files[0];
      const objectUrl = URL.createObjectURL(file);
      setPreview(objectUrl);
  
      setFormData((prevData) => ({
        ...prevData,
        [name]: file,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleBrandsMultiSelectChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      brandIds: value,
    }));
  };

  const handleIncludesCountriesMultiSelectChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      includedCountries: value,
    }));
  };

  const handleExcludedCountriesMultiSelectChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      excludedCountries: value,
    }));
  };
  

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="label">Name</label>
        <input
          type="text"
          id="label"
          name="label"
          value={formData.label}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>

      <div className="form-group">
        <label htmlFor="url">URL</label>
        <input
          type="text"
          id="url"
          name="url"
          value={formData.url}
          onChange={handleInputChange}
          className="form-control"
        />
      </div>

      <div className="form-group">
        <label htmlFor="image">Logo</label>
        <div className="form-control m-2 p-3 d-flex flex-column">
          {!isCreating && <img src={preview} alt="row.img" className="img-responsive" /> }
          <input
            type="file"
            accept="image/*"
            id="image"
            name="image"
            onChange={handleInputChange}
          />
        </div>
      </div>

      {!isCreating && (
        <div className="form-group">
          <label htmlFor="image">Status</label>
          <select 
            className="form-control user-status-select leads-status-select"
            name="status"
            defaultValue={row.status}
            onChange={handleInputChange}
          >
            <option value>Active</option>
            <option value={false}>Inactive</option>
          </select>
        </div>
      )}

      <div className="form-group mt-2 custom-milti-select">
        <span>Brands</span>
        <MultiSelect
          name="brandIds"
          options={brandsOptions}
          value={formData.brandIds}
          onChange={handleBrandsMultiSelectChange}
          labelledBy="Select"
          includeSelectAllOption="false"
        />
      </div>

      <div className="form-group mt-2 custom-milti-select">
        <span>Included Countries</span>
        <MultiSelect
          name="includedCountries"
          options={countriesOptions}
          value={formData.includedCountries}
          onChange={handleIncludesCountriesMultiSelectChange}
          labelledBy="Select"
          includeSelectAllOption="false"
        />
      </div>

      <div className="form-group mt-2 custom-milti-select">
        <span>Excluded Countries</span>
        <MultiSelect
          name="includedCountries"
          options={countriesOptions}
          value={formData.excludedCountries}
          onChange={handleExcludedCountriesMultiSelectChange}
          labelledBy="Select"
          includeSelectAllOption="false"
        />
      </div>

      <div className="m-2 d-flex justify-content-end">
        <button className="btn btn-default" type="submit">
          {!isCreating ? 'Save' : 'Add'}
        </button>
      </div>
    </form>
  );
}
