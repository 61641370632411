import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { DonutChart } from '../Charts/DonutChart/DonutChart';
import { NoDataAvailableTableIcon } from '../components/NoDataAvailableTable';
import style from './StatusFTD.module.css';
import { selectAffiliatesDashboardColorDataByStatus, selectAffiliatesDashboardDataByStatus } from '../../../redux/affiliatesDashboard/affiliatesDashboardSelectors';
import { useWindowSize } from '../../../hooks/useWindowSize';

function StatusFTD({ downloadDepositsByStatusHandler }) {
  const [screenWidth, screenHeight] = useWindowSize();

  const donutData = useSelector(selectAffiliatesDashboardDataByStatus);
  const colorData = useSelector(selectAffiliatesDashboardColorDataByStatus);

  const isMobile = screenWidth <= 499;

  return (
    <div className={style.tableWrapper}>
      <div className={style.allDepositsTitleContainer}>
        <h3 className={style.allDepositsTitle}>FTD by status</h3>
        {/* <button type="button" onClick={downloadDepositsByStatusHandler} className={style.allDepositsAnalysisBtn}>
          <FileDownloadOutlinedIcon sx={{ color: '#C4C6D0' }} />
        </button> */}
      </div>
      <div>
        {
          (donutData?.length > 1) 
            ? <DonutChart data={donutData} colors={colorData} isMobile={isMobile} />
            : ( 
              <div className={style.noDataWrapper}>
                <NoDataAvailableTableIcon />
                <p className={style.noDataWrapperTitle}>No data available</p>
              </div>
            )
        } 
      </div>
    </div>
  );
}

export default StatusFTD;
