import React, { useMemo, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faForward, faFile, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { UserPhoto } from './UserPhoto';

export function ActiveChat({
  activeChat, setNewMessage, handleSendMessage, chatBoxRef, fileInputRef, newMessage, handleReadMessages, file, setFile, 
}) {
  const isFileLoaded = useMemo(() => file && file.name, [file]);

  useEffect(() => {
    if (file && file.size > 3007152) {
      toast.error('File bigger then 3 MB');
      setFile(null);
      // eslint-disable-next-line no-param-reassign
      fileInputRef.current.value = '';
    }
  }, [file]);

  const splitFileName = (fileName) => {
    const [name, ext] = fileName.split('.');

    return (
      <>
        <span className="truncate-text">{name}</span>
        <span>{`.${ext}`}</span>
      </>
    );
  };

  return (
    <div className="chat-text-container ps-0 h-100">

      {!!Object.keys(activeChat).length && (
      <>
        <div className="settings-tray">
          <div className="friend-drawer no-gutters friend-drawer--grey">
            <UserPhoto imageId={activeChat.userData.profileImage} />
            <a target="_blank" href={`/user-detail/${activeChat.userData._id}`} className="text" rel="noreferrer">
              <h6>{`${activeChat.userData.firstName} ${activeChat.userData.lastName}`}</h6>
            </a>
          </div>
        </div>

        <div className="chat-panel">
          <div className="chat-messages" ref={chatBoxRef}>
            {activeChat.messages.map((message, index = 0) => {
              const direction = message.sender === activeChat.crmUser ? 'right' : 'left';
              return (
                <div className="chat__message-row" key={message._id}>
                  {(!message.isRead && message.sender !== activeChat.crmUser) && <span className="chat__unread-dot" />}
                  <div key={index} className={`chat-bubble chat-bubble--${direction}`}>
                    {message.content}
                    {!!message.fileName && <a aria-label="Open" target="_blank" className="chat-content__file" href={`${process.env.REACT_APP_SERVER_URL}/images/${message.fileName}`} download rel="noreferrer"><FontAwesomeIcon size="xl" className="pointer" icon={faFile} /></a> }
                  </div>
                </div>
              );
            })}
          </div>

          <div className="chat-box-tray">
            <div className="chat-input__upload">
              <label htmlFor="file-input">
                <FontAwesomeIcon icon={faPaperclip} className="svg" />
                {isFileLoaded && <span className="chat-input__filename">{splitFileName(file.name)}</span>}
              </label>
              <input id="file-input" accept="image/*" type="file" ref={fileInputRef} onChange={(e) => setFile(e.target.files[0])} />
            </div>
            <input
              type="text"
              placeholder="Type your message here..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onFocus={() => handleReadMessages(activeChat._id, activeChat.crmUser, activeChat.user)}
              onKeyPress={(e) => (e.key === 'Enter' ? handleSendMessage() : null)}
            />
            <FontAwesomeIcon
              className="svg"
              icon={faForward}
              onClick={() => handleSendMessage()}
            />
          </div>
        </div>
      </>
      )}
    </div>
  );
};
