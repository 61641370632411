import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';

import { ModalContext } from '../../context';
import { AddVariablesModal } from './modals/AddVariablesModal/AddVariablesModal';
import { getAllVariables } from '../../redux/variables/variablesActions';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';
import { VariablesSchema } from '../../DataTableSchemas/VariablesSchema';
import { useDebounce } from '../../hooks/useDebounce';
import { getBrands } from '../../redux/brand/brandActions';

export function Variables() {
  const dispatch = useDispatch();
  const { paginatedData, totalCount } = useSelector((state) => state.variables.variables);
  const { showModal } = useContext(ModalContext);
  const [loader, setLoader] = useState(false);
  const [isPaginationDT, setIsPaginationDT] = useState(false); 
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [processFilter, setProcessFilter] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  const [valueFilter, setValueFilter] = useState(null);
  const [brandNameFilter, setBrandNameFilter] = useState(null);
  const [variableFilter, setVariableFilter] = useState({});
  const [inputFieldError, setInputFieldError] = useState({
    typeValueIsValid: false,
    valueFilterIsValid: false,
    processFilterIsValid: false,
    brandNameFilterIsValid: false,
    errorMessage: '',
  });

  const debounceProcessFilterCallback = ({ value, key }) => {
    if ((value.length >= 3 || value.length === 0 || Array.isArray(value)) && variableFilter[key] !== value) {
      setVariableFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  const debounceTypeFilterCallback = ({ value, key }) => {
    if ((value.length >= 3 || value.length === 0 || Array.isArray(value)) && variableFilter[key] !== value) {
      setVariableFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  const debounceValueFilterCallback = ({ value, key }) => {
    if ((value.length >= 0 || Array.isArray(value)) && variableFilter[key] !== value) {
      setVariableFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  const debounceBrandNameFilterCallback = ({ value, key }) => {
    if ((value.length >= 3 || value.length === 0 || Array.isArray(value)) && variableFilter[key] !== value) {
      setVariableFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  useDebounce(processFilter, 1000, (value) => debounceProcessFilterCallback({ value, key: 'actions' }));
  useDebounce(typeValue, 1000, (value) => debounceTypeFilterCallback({ value, key: 'type' }));
  useDebounce(valueFilter, 1000, (value) => debounceValueFilterCallback({ value, key: 'value' }));
  useDebounce(brandNameFilter, 1000, (value) => debounceBrandNameFilterCallback({ value, key: 'brandName' }));

  const filterStorageName = 'DataTable_variables/filter';
  const paginationStorageName = 'DataTable_variables/pagination';

  const setStoredPagination = () => {
    const variablesRowsJSON = localStorage.getItem(paginationStorageName);
    if (variablesRowsJSON) {
      const filterRows = JSON.parse(variablesRowsJSON);
      setRowsPerPage(filterRows?.limit || 25);
      setPage(filterRows?.page || 1);
    }

    setIsPaginationDT(true);
  };

  const setStoredFilterData = () => {
    const storageData = localStorage.getItem(filterStorageName);
    if (storageData) {
      const filters = JSON.parse(storageData);
      
      setProcessFilter(filters?.actions || null);
      setTypeValue(filters?.type || null);
      setValueFilter(filters?.value || null);
      setBrandNameFilter(filters?.brandName || null);

      setVariableFilter(filters || {});
    }
  };

  const getAllStoredData = () => {
    setStoredFilterData();
    setStoredPagination();
  };

  useEffect(() => {
    setLoader(true); 
    getAllStoredData();
    dispatch(getBrands());
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (isPaginationDT) {
        localStorage.setItem(filterStorageName, JSON.stringify(variableFilter));
        await dispatch(getAllVariables({ page, limit: rowsPerPage, query: variableFilter }));
        setLoader(false);
      }
    };
   
    fetchData();
  }, [isPaginationDT, variableFilter, page, rowsPerPage]);

  const handlePageChange = (page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: rowsPerPage, page }));
    setPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage, page }));
    setRowsPerPage(currentRowsPerPage);
  };

  const columns = VariablesSchema(
    processFilter, 
    setProcessFilter,
    typeValue, 
    setTypeValue,
    valueFilter, 
    setValueFilter,
    brandNameFilter,
    setBrandNameFilter,
    inputFieldError,
    setInputFieldError,
  );


  return (
    loader 
      ? <FullPageTransparentLoader />
      : (
        <div className="content-wrapper right-content-wrapper">
          <div className="content-box">
            <h3>
              Variables Page
            </h3>
            <div className="action__btn-row">
              <button
                className="btn btn-default"
                type="button"
                style={{ 
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  backgroundColor: 'green',
                }}
                onClick={() => showModal({
                  bodyContent: <AddVariablesModal />,
                  headerContent: <h3>Add New Variable</h3>, 
                })}
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  size="xs"
                />
                Add
              </button>
            </div>
            <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
              {
                  isPaginationDT
                    && (
                      <DataTable
                        columns={columns}
                        data={paginatedData}
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={totalCount}
                        paginationPerPage={rowsPerPage}
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsPerPageChange}
                        paginationDefaultPage={page}
                        persistTableHead
                        theme="solarizedd"
                        progressComponent={<div className="datatable-loader__background" />}
                      />
                    )
                }
            </div>
          </div>
        </div>
      )
  );
}
