import React, {
  useEffect, useState, useContext, 
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import {
  selectIsSuperUser, selectTotalCount, 
} from '../../redux/emailTemplates/emailTemplatesSelectors';
import { makeSuperUserCheck } from '../../redux/emailTemplates/emailTemplatesActions';
import { ModalContext } from '../../context';
import { SmsServicesColumns } from '../../DataTableSchemas/SmsServicesColumns/SmsServicesColumns';
import SmsServiceCreate from './SmsServiceCreate/SmsServiceCreate';
import {
  selectConfigNameFilter, selectIsActiveFilter, selectIsSmsServicesLoading, selectServiceNameFilter, selectSmsServices, 
} from '../../redux/smsServices/smsServicesSelectors';
import { fetchIntegratedSmsServices, fetchSmsServices } from '../../redux/smsServices/smsServicesActions';
import { useDebounce } from '../../hooks/useDebounce';

const useInitEffect = (setStoredPagination) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(makeSuperUserCheck());
    setStoredPagination();
    dispatch(fetchIntegratedSmsServices());
  }, []);
};

function SmsServices() {
  const dispatch = useDispatch();
  const { showModal, hideModal } = useContext(ModalContext);

  const paginationStorageName = 'DataTable_smsServices/listing_pagination';

  // templates variables
  const isSuperUser = useSelector(selectIsSuperUser);
  const smsServices = useSelector(selectSmsServices);

  const isDataLoading = useSelector(selectIsSmsServicesLoading);

  // filters variables
  const configNameFilter = useSelector(selectConfigNameFilter);
  const serviceNameFilter = useSelector(selectServiceNameFilter);
  const isActiveFilter = useSelector(selectIsActiveFilter);

  // pagination variables
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const totalTableCount = useSelector(selectTotalCount);
  const [isPaginationDT, setIsPaginationDT] = useState(false);

  const handleCreateBtn = () => {
    showModal({
      headerContent: <h3>Create Sms Service</h3>,
      bodyContent: <SmsServiceCreate hideModal={hideModal} />,
    });
  };

  const setStoredPagination = () => {
    const ListingPaginationRowsJSON = localStorage.getItem(paginationStorageName);
    if (ListingPaginationRowsJSON) {
      const filterRows = JSON.parse(ListingPaginationRowsJSON);
      setRowsPerPage(filterRows.limit || 25);
    }

    setIsPaginationDT(true);
  };

  const handleRowsPerPageChange = (currentRowsPerPage) => {
    setRowsPerPage(currentRowsPerPage);
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage }));
  };

  const handlePageChange = (page) => setPage(page);

  useEffect(() => {
    dispatch(fetchSmsServices({
      page,
      per_page: rowsPerPage,
      config_name: configNameFilter,
      service_name: serviceNameFilter,
      is_active: isActiveFilter,
    }));
  }, [page, rowsPerPage, serviceNameFilter, isActiveFilter]);

  useDebounce(configNameFilter, 500, () => {
    dispatch(fetchSmsServices({
      page,
      per_page: rowsPerPage,
      config_name: configNameFilter,
      service_name: serviceNameFilter,
      is_active: isActiveFilter,
    }));
  });

  useInitEffect(setStoredPagination);

  const smsServicesCol = SmsServicesColumns();

  return (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Sms Services Details</h3>
        {
          isSuperUser && (
            <button 
              type="button" 
              style={{ backgroundColor: 'green' }}
              className="btn btn-default btn-lg me-1 p-1"
              onClick={handleCreateBtn}
            >
              + Add
            </button>
          )
        }
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
          {
            isPaginationDT
              && (
                <DataTable
                  columns={smsServicesCol}
                  data={smsServices}
                  pagination
                  highlightOnHover
                  paginationServer
                  paginationTotalRows={totalTableCount}
                  paginationPerPage={rowsPerPage}
                  paginationRowsPerPageOptions={[25, 50, 100, 500]}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  onChangePage={handlePageChange}
                  persistTableHead
                  selectableRows
                  selectableRowsVisibleOnly
                  theme="solarizedd"
                  progressPending={isDataLoading}
                  progressComponent={<div className="datatable-loader__background" />}
                />
              )
          }
        </div>
        <br />
      </div>
    </div>
  );
}

export default SmsServices;
