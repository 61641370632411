import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dropdown, Row, Col, Button,
} from 'react-bootstrap';
import { geAccountCurrencies } from '../../../../redux/cfdAccountCurrency/cfdAccountCurrencyActions';
import { getCFDAccountTypes } from '../../../../redux/cfdAccountType/cfdAccountTypeActions';
import { createUserAccount } from '../../../../redux/cfdAccount/cfdAccountActions';


function CreateAccountModal({ userId, closeModal }) {
  const dispatch = useDispatch();
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const [formData, setFormData] = useState({});

  const accountCurrencies = useSelector((state) => state.cfdAccountCurrencies);
  const accountTypes = useSelector((state) => state.cfdAccountTypes);
  const isBtnDisabled = useMemo(() => (!selectedCurrency || !selectedCurrency._id || !selectedType || !selectedType._id), [selectedCurrency, selectedType]);

  useEffect(() => {
    Promise.allSettled([
      dispatch(geAccountCurrencies()),
      dispatch(getCFDAccountTypes()),
    ]);
  }, []);

  const handleInputChange = (name, value) => {
    if (Number(value) < 0) value = '0';

    setFormData((prev) => ({
      ...prev,
      [name]: value.replace(/[^\d.]/g, '').replace(/^0+(\d)/, '0$1'),
    }));
  };

  const submit = () => {
    if (selectedCurrency === null || selectedType === null) return;

    const data = {
      ...formData,
      userId,
      cfdAccountCurrencyId: selectedCurrency._id,
      accountTypeId: selectedType._id,
    };

    dispatch(createUserAccount(data));
    closeModal();
  };

  return (
    <>
      <Row className="mb-15">
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2 label-with-info">
              <span>Currency</span>
            </label>
            <Dropdown className="t-settings__dropdown">
              <Dropdown.Toggle className="t-settings_dropdown-btn" variant="">{selectedCurrency ? selectedCurrency.symbol : 'Select'}</Dropdown.Toggle>
              <Dropdown.Menu>
                {accountCurrencies.map((currency) => (
                  <Dropdown.Item key={currency._id} onClick={() => setSelectedCurrency(currency)}>{currency.symbol}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <Row className="mb-15">
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2 label-with-info">
              <span>Type</span>
            </label>
            <Dropdown className="t-settings__dropdown">
              <Dropdown.Toggle className="t-settings_dropdown-btn" variant="">{selectedType ? selectedType.name : 'Select'}</Dropdown.Toggle>
              <Dropdown.Menu>
                {accountTypes.map((type) => (
                  <Dropdown.Item key={type._id} onClick={() => setSelectedType(type)}>{type.name}</Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
      </Row>
      <Row className="mb-15">
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2 label-with-info">
              <span>Leverage</span>
            </label>
            <input
              type="text"
              id="leverage"
              name="leverage"
              className="form-control"
              onChange={({ target: { name, value } }) => handleInputChange(name, value)}
              value={formData.leverage ?? 0}
            />
          </div>
        </Col>
      </Row>
      <Row className="mb-15">
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2 label-with-info">
              <span>Lot Step</span>
            </label>
            <input
              type="text"
              id="lotStep"
              name="lotStep"
              className="form-control"
              onChange={({ target: { name, value } }) => handleInputChange(name, value)}
              value={formData.lotStep ?? 0}
            />
          </div>
        </Col>
      </Row>
      <Row className="mb-15">
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2 label-with-info">
              <span>Lot Size</span>
            </label>
            <input
              type="text"
              id="lotSize"
              name="lotSize"
              className="form-control"
              onChange={({ target: { name, value } }) => handleInputChange(name, value)}
              value={formData.lotSize ?? 0}
            />
          </div>
        </Col>
      </Row>
      <Row className="mb-15">
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2 label-with-info">
              <span>Commission %</span>
            </label>
            <input
              type="text"
              id="commission"
              name="commission"
              className="form-control"
              onChange={({ target: { name, value } }) => handleInputChange(name, value)}
              value={formData.commission ?? 0}
            />
          </div>
        </Col>
      </Row>
      <Row className="mb-15">
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2 label-with-info">
              <span>Swap Long %</span>
            </label>
            <input
              type="text"
              id="swapLong"
              name="swapLong"
              className="form-control"
              onChange={({ target: { name, value } }) => handleInputChange(name, value)}
              value={formData.swapLong ?? 0}
            />
          </div>
        </Col>
      </Row>

      <Row className="mb-15">
        <Col md={12}>
          <div className="form-group me-2">
            <label className="control-label mb-2 label-with-info">
              <span>Swap Short %</span>
            </label>
            <input
              type="text"
              id="swapShort"
              name="swapShort"
              className="form-control"
              onChange={({ target: { name, value } }) => handleInputChange(name, value)}
              value={formData.swapShort ?? 0}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={12} style={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Button variant="secondary" onClick={() => closeModal()}>Cancel</Button>
          <Button variant="primary" className="ms-2" disabled={isBtnDisabled} onClick={() => submit()}>Add</Button>
        </Col>
      </Row>
    </>

  );
}

export default CreateAccountModal;
