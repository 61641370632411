import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { deleteOtherPsp, updateOtherPsp } from '../redux/pspOthers/pspOthersActions';
import { selectAllCountries } from '../redux/countries/countriesSelectors';

export const OtherPspSchema = (OtherPspModal, showModal) => {
  const dispatch = useDispatch();
  const brands = useSelector((state) => state.brands);
  const countries = useSelector(selectAllCountries);
  const { currentUserPermissions } = useSelector((state) => state.crmUser);
  const isUserCanEditPspOther = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('psp_other_edit');
  const isUserCanDeletePspOther = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('psp_other_delete');

  const deleteAction = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteOtherPsp(id));
      }
    });
  };
  
  const handleChangeStatus = async (e, id) => {
    if (e.target.value) {
      const formDataToSend = new FormData();
      formDataToSend.append('status', e.target.value);
      await dispatch(updateOtherPsp(id, formDataToSend));
    }
  };

  const makeListOfRecords = ({ state = [], ids = [], nameField = '' }) => {
    let list = '';

    // eslint-disable-next-line no-restricted-syntax
    for (const record of state) {
      if (ids.includes(record._id)) {
        if (list.length < 25) {
          list += `${record[nameField]}, `;
        } else {
          break;
        }
      }
    }

    list = list.length ? `${list.slice(0, -2)}...` : list;

    return list;
  };

  const columns = [
    {
      name: 'Other PSP',
      cell: (row) => row.label,
    },
    {
      name: 'Image',
      cell: (row) => (<img src={`${process.env.REACT_APP_SERVER_URL}/images/${row.imageUrl}`} alt="row.img" height="40px" />),
    },
    {
      name: 'Brands',
      cell: ({ brandIds }) => (makeListOfRecords({ state: brands, ids: brandIds, nameField: 'name' })),
    },
    {
      name: 'Included Countries',
      cell: ({ includedCountries }) => (makeListOfRecords({ state: countries, ids: includedCountries, nameField: 'nicename' })),
    },
    {
      name: 'Excluded Countries',
      cell: ({ excludedCountries }) => (makeListOfRecords({ state: countries, ids: excludedCountries, nameField: 'nicename' })),
    },
    {
      name: 'Status',
      cell: (row) => (
        isUserCanEditPspOther 
          ? (
            <select 
              className="form-control user-status-select leads-status-select"
              name="status"
              value={row.status}
              onChange={(e) => handleChangeStatus(e, row._id)}
            >
              <option value>Active</option>
              <option value={false}>Inactive</option>
            </select>
          ) : <div>{row.status ? 'Active' : 'Inactive'}</div>
      ),
    },
    {
      name: 'Referal Link',
      cell: (row) => row.url,
    },
    {
      name: 'Actions',
      cell: (row) => (
        isUserCanDeletePspOther || isUserCanEditPspOther ? (
          <div className="action-btn">
            {isUserCanEditPspOther && (
              <button
                type="button"
                className="btn btn-success btn-sm me-1 p-1"
                onClick={() => showModal({
                  bodyContent: <OtherPspModal row={row} type="edit" />,
                  headerContent: <h3>Edit PSP</h3>, 
                })}
              >
                <FontAwesomeIcon icon={faPencil} />
              </button>
            )}
            {isUserCanDeletePspOther && (
              <button
                type="button"
                className="btn btn-danger btn-sm me-1 p-1"
                onClick={() => deleteAction(row._id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            )}
          </div>
        ) : '-'
      ),
    },
  ];

  return columns;
};
