/* eslint-disable default-param-last */
import {
  GET_ADMIN_ADDRESSES, GET_ADMIN_ADDRESSES_NETWORK, GET_ADMIN_ADDRESS, GET_WALLETS_BY_ADDRESS, ADD_ADMIN_ADDRESS, EDIT_ADMIN_ADDRESS, DELETE_ADMIN_ADDRESS, SET_DEFAULT_ADMIN_ADDRESS, TOGGLE_STATE, ERROR_STATE,
} from './adminAddressTypes';

const initialState = {
  adminAddresses: [],
  adminAddressesNetwork: [],
  adminAddress: {},
  wallets: [],
  adminAddressesfetched: false,
  adminAddressAdded: false,
  adminAddressfetched: false,
  adminAddressesNetworkfetched: false,
  adminAddressEditted: false,
  adminAddressDeleted: false,
  adminAddressDefaultSet: false,
  error: false,
};

const adminAddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WALLETS_BY_ADDRESS:
      return {
        ...state,
        wallets: action.payload,
      };
    case GET_ADMIN_ADDRESSES:
      return {
        ...state,
        adminAddresses: action.payload,
        adminAddressesfetched: true,
      };
    case GET_ADMIN_ADDRESSES_NETWORK:
      return {
        ...state,
        adminAddressesNetwork: action.payload,
        adminAddressesNetworkfetched: true,
      };
    case GET_ADMIN_ADDRESS:
      return {
        ...state,
        adminAddress: action.payload,
        adminAddressfetched: true,
      };
    case ADD_ADMIN_ADDRESS:
      return {
        ...state,
        adminAddresses: [state.adminAddresses, action.payload],
        adminAddressAdded: true,
      };
    case EDIT_ADMIN_ADDRESS:
      return {
        ...state,
        adminAddress: action.payload,
        adminAddresses: state.adminAddresses.map((item) => (item._id === action.payload._id ? action.payload : item)), 
        adminAddressEditted: true,
      };
    case DELETE_ADMIN_ADDRESS:
      return {
        ...state,
        adminAddresses: state.adminAddresses.filter((item) => item._id !== action.payload),
        adminAddressDeleted: true,
      };
    case SET_DEFAULT_ADMIN_ADDRESS:
      return {
        ...state,
        adminAddressDefaultSet: true,
        adminAddresses: state.adminAddresses.map((item) => {
          const isCurrencyIdEqual = item.currency._id === action.payload.currency._id;
          const isAddressIdEqual = item._id === action.payload._id;

          const isNetworkExists = item?.network !== undefined;
          const isNetworksEqual = isNetworkExists ? item.network === action.payload.network : true;

          if (isCurrencyIdEqual && !isAddressIdEqual && isNetworksEqual) {
            return {
              ...item,
              isDefault: false,
            };
          };
          if (isCurrencyIdEqual && isAddressIdEqual && isNetworksEqual) {
            return {
              ...item,
              isDefault: true,
            };
          }

          return item;
        }),
      };
    case TOGGLE_STATE:
      return {
        ...state,
        adminAddressesfetched: false,
        adminAddressAdded: false,
        adminAddressfetched: false,
        adminAddressEditted: false,
        adminAddressDeleted: false,
        adminAddressDefaultSet: false,
        adminAddressesNetworkfetched: false,
        error: false,
      };
    case ERROR_STATE:
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};

export default adminAddressReducer;
