import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button, MenuItem, Select,
} from '@mui/material';
import { importCSVFile } from '../../../redux/users/userActions';
import selectorStyle from './SelectorComponent.module.css';
import style from './UserUploadModal.module.css';
import { geAccountCurrencies } from '../../../redux/cfdAccountCurrency/cfdAccountCurrencyActions';
import FullPageTransparentLoader from '../../FullPageTransparentLoader/fullPageTransparentLoader';

const userTableRelyFields = [
  {
    title: 'none',
    value: 'none',
  },
  {
    title: 'Email',
    value: 'email',
  },
  {
    title: 'Username',
    value: 'username',
  },
  {
    title: 'Full Name',
    value: 'fullName',
  },
  {
    title: 'First Name',
    value: 'firstName',
  },
  {
    title: 'Last Name',
    value: 'lastName',
  },
  {
    title: 'Country',
    value: 'countryCode',
  },
  {
    title: 'Phone Number',
    value: 'phone',
  },
  {
    title: 'Comment',
    value: 'comment',
  },
  {
    title: 'Client Status',
    value: 'clientStatus',
  },
  {
    title: 'Assigned To',
    value: 'assignedTo',
  },
  {
    title: 'Balance',
    value: 'balance',
  },
  {
    title: 'Currency',
    value: 'currency',
  },
  {
    title: 'Affiliate Name',
    value: 'affiliateName',
  },
  {
    title: 'Source',
    value: 'source',
  },
  {
    title: 'Funnel',
    value: 'source',
  },
  {
    title: 'Funnel Name',
    value: 'source',
  },
];

const requiredFields = [
  'email',
  'phone',
  'fullName',
  'firstName',
  'lastName',
  'countryCode',
];

function cleanString(input) {
  const regex = /[^a-zA-Z0-9]+/g;
  return input.replace(regex, '');
}

function SelectorItem({
  title, options, onChange, isNoneAcceptable = false, width = '300px', ...rest
}) {
  if (options?.length && typeof options[0] === 'string') {
    options = options.map((option) => ({ title: option, value: option }));
  }

  const [selected, setSelected] = useState(options[0]?.value);

  useEffect(
    () => onChange(selected), 
    [selected],
  );

  const handleChange = (data, option) => setSelected(option.props.value);

  return (
    <div className={selectorStyle.wrapper}>
      <div className={selectorStyle.title}>{title}</div>
      <div className={selectorStyle.selectorWrapper}>
        <Select
          sx={{
            color: 'white',
            border: '1px solid #383b3e', 
            background: '#434b53',
            padding: '2px 15px',
            borderRadius: '4px',
            width, 
          }}
          error={!isNoneAcceptable && selected === 'none'}
          variant="standard"
          value={selected}
          onChange={handleChange}
          {...rest}
        >
          {
            options.map((option) => {
              const clearedOptionTitle = cleanString((option.title ?? '').trim().toLowerCase());
              const clearedRequiredField = cleanString((option.value ?? '').trim().toLowerCase());
              const clearedValue = cleanString(title.trim().toLowerCase());

              if ((clearedRequiredField === clearedValue || clearedOptionTitle === clearedValue) && option.value !== selected) {
                setSelected(option.value);
              }

              return <MenuItem key={option.value} value={option.value}>{option.title}</MenuItem>;
            })
          }
        </Select>
      </div>
    </div>
  );
}

function UserUploadModal({
  defaultRole = 'Lead', uploadedFileFields, hideModal, handlePageChange, csvFile, csvFileExtension,
}) {
  const dispatch = useDispatch();

  const filteredFields = useMemo(() => uploadedFileFields.filter((field) => field !== null), [uploadedFileFields]);

  const accountCurrencies = useSelector((state) => state.cfdAccountCurrencies);
  const crmUsers = useSelector((state) => state.crmUser?.crmUsers);
  const brands = useSelector((state) => state.brands);

  const createAccountSelectorData = ['No', 'Yes', 'Yes, with balance and currency form file'];

  const assignedToUsers = [
    { _id: 'none', username: 'none' }, 
    { _id: 'user_id_from_file', username: 'Set userId from file' }, 
    ...crmUsers.map((user) => ({
      ...user,
      username: `${user.firstName} ${user.lastName}`,
    })),
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [requiredField, setRequiredField] = useState('');
  const [isUploadBtnAvailable, setUploadBtnStatus] = useState(false);
  const [isBalanceFromFileSelected, setIsBalanceFromFileSelected] = useState(false);
  const [accountCreatingType, setAccountCreatingType] = useState('No');
  const [configuredData, setConfiguredData] = useState({});
  const [additionalInfo, setAdditionalInfo] = useState({
    role: defaultRole,
    is_create_account: 'No',
    assigned_to: 'none',
    balance: 0,
    account_currency: accountCurrencies.length > 0 ? accountCurrencies[0]._id : null,
  });

  useEffect(() => {
    if (accountCurrencies.length === 0) {
      dispatch(geAccountCurrencies());
    }
  }, [accountCurrencies]);


  const checkConfiguration = (requiredFields, linkedFields) => {
    const linkedSet = new Set(linkedFields);
    const missingFields = [];

    // Check for the general required fields excluding the names
    const generalRequiredFields = requiredFields.filter((field) => !['fullName', 'firstName', 'lastName'].includes(field));
    generalRequiredFields.forEach((field) => {
      if (!linkedSet.has(field)) {
        missingFields.push(field);
      }
    });

    // Check the conditions for names
    const hasFullName = linkedSet.has('fullName');
    const hasFirstName = linkedSet.has('firstName');
    const hasLastName = linkedSet.has('lastName');

    if (!hasFullName && (!hasFirstName || !hasLastName)) {
    // Need either fullName or both firstName and lastName
      missingFields.push('fullName');
      if (!hasFirstName) missingFields.push('firstName');
      if (!hasLastName) missingFields.push('lastName');
    }

    // Remove fullName if firstName and lastName are present
    if (hasFirstName && hasLastName) {
      const fullNameIndex = missingFields.indexOf('fullName');
      if (fullNameIndex !== -1) missingFields.splice(fullNameIndex, 1);
    }

    return missingFields;
  };

  useEffect(() => {
    const noneFilterResult = Object.entries(configuredData).filter((bunch) => bunch[1] === 'none');
    const linkedFields = Object.values(configuredData);
 
    const missingFields = checkConfiguration(requiredFields, linkedFields);
    const requiredFieldText = missingFields.length ? missingFields.join(', ') : '';
    setRequiredField(requiredFieldText);

    if (linkedFields.includes('balance') && linkedFields.includes('currency')) {
      setIsBalanceFromFileSelected(true);
      setAccountCreatingType(createAccountSelectorData[2]);
      setAdditionalInfo({
        ...additionalInfo,
        is_create_account: 'from_file',
      });
    } else {
      setIsBalanceFromFileSelected(false);
    }

    setUploadBtnStatus(noneFilterResult.length === 0);
  }, [configuredData]);

  const findFieldByValue = (array, value = '') => array.find((option) => {
    const requiredField = option.value ?? '';
    const title = option.title ?? '';

    const clearedOptionTitle = cleanString(title.trim().toLowerCase());
    const clearedRequiredField = cleanString(requiredField.trim().toLowerCase());
    const clearedValue = cleanString(value.trim().toLowerCase());

    return clearedRequiredField === clearedValue || clearedOptionTitle === clearedValue;
  });

  // setup default input state values
  useEffect(() => {
    const data = {};

    filteredFields.forEach((key) => {
      const field = findFieldByValue(userTableRelyFields, key);
      data[key] = field?.value ?? userTableRelyFields[0].value;
    });

    setConfiguredData(data);

    setAdditionalInfo({
      ...additionalInfo,
      brand: brands[0]._id,
    });
  }, []);

  const handleBalanceInput = ({ target }) => {
    const balanceValue = target.value;

    setAdditionalInfo({
      ...additionalInfo,
      balance: balanceValue,
    });
  };
  
  const handleSelectorChange = (key, option) => {
    setConfiguredData({
      ...configuredData,
      [key]: option,
    });
  };

  const handleCustomSelectorChange = (key, option) => {
    if (key === 'brand') {
      const brandData = brands.find((brand) => brand.name === option);
      option = brandData._id;
    }
    if (key === 'assigned_to') {
      const assignedToUserData = assignedToUsers.find((user) => user.username === option);
      option = assignedToUserData._id;
    }
    if (key === 'account_currency') {
      const selectedCurrency = accountCurrencies.find((currency) => currency.symbol === option);
      option = selectedCurrency._id;
    }
    if (key === 'is_create_account') {
      setAccountCreatingType(option);
      option = option !== 'No' && option !== 'Yes' ? 'from_file' : option;
    }

    setAdditionalInfo({
      ...additionalInfo,
      [key]: option,
    });
  };

  const uploadConfiguredLeadFile = async () => {
    const formData = new FormData();

    setIsLoading(true);

    if (csvFile) {
      formData.append('csvFile', csvFile);
      formData.append('csvFileExtension', csvFileExtension);
      formData.append('configureData', JSON.stringify(configuredData));
      formData.append('additionalInfo', JSON.stringify(additionalInfo));

      await dispatch(importCSVFile(formData));

      // get actual users with bound country, assign_to and balances info
      handlePageChange(1);
    }

    setIsLoading(false);
    hideModal();
  };

  const getColumnData = () => {
    // brand, assigned to, create account for each user
    const defaultFieldAmount = 3;
    const accountCreateFieldsAmount = accountCreatingType === 'Yes' ? 2 : 0;
    const shift = defaultFieldAmount + accountCreateFieldsAmount;
    
    const midIndex = Math.floor((filteredFields.length - shift) / 2);

    const col1Fields = filteredFields.slice(0, midIndex);
    const col2Fields = filteredFields.slice(midIndex, filteredFields.length);

    return {
      col1Fields,
      col2Fields,
    };
  };

  const { col1Fields, col2Fields } = getColumnData();
  const isFieldsAmountReachLimit = filteredFields.length >= userTableRelyFields.length;

  return (
    isLoading ? <FullPageTransparentLoader /> : (
      <div className={style.wrapper}>
        <div className={style.configureSelectorsWrapper}>
          <div className={style.col1}>
            <SelectorItem
              title="Brand"
              options={brands.map((brand) => brand.name)}
              onChange={(brand) => handleCustomSelectorChange('brand', brand)}
            />
            <SelectorItem
              title="Assigned to"
              options={assignedToUsers.map((user) => user.username)}
              onChange={(user) => handleCustomSelectorChange('assigned_to', user)}
              isNoneAcceptable
            />
            <SelectorItem
              title="Create account for each new user"
              options={createAccountSelectorData}
              onChange={(option) => handleCustomSelectorChange('is_create_account', option)}
              value={accountCreatingType}
              disabled={isBalanceFromFileSelected}
            />
            {
              accountCreatingType === 'Yes' && (
                <div className={style.balanceWrapper}>
                  <div className={style.balanceInputWrapper}>
                    <div className={style.balanceInputTitle}>
                      Add balance
                      <br />
                    </div>
                    <div className={style.balanceInputBlock}>
                      <input
                        value={additionalInfo.balance}
                        onChange={handleBalanceInput}
                        className={style.balanceInput}
                        type="number"
                      />
                    </div>
                  </div>
                  <SelectorItem
                    title="Select currency"
                    options={accountCurrencies.map((currencyData) => currencyData.symbol)}
                    onChange={(option) => handleCustomSelectorChange('account_currency', option)}
                    width="290px"
                  />
                </div>
              )
            }
            {
              col1Fields.map((field) => (
                <SelectorItem 
                  key={field}
                  title={field} 
                  options={userTableRelyFields}
                  onChange={(option) => handleSelectorChange(field, option)}
                />
              ))
            }
          </div>
          <div className={style.col2}>
            {
              col2Fields.map((field) => (
                <SelectorItem 
                  key={field}
                  title={field} 
                  options={userTableRelyFields}
                  onChange={(option) => handleSelectorChange(field, option)}
                />
              ))
            }
          </div>
        </div>
        <div className={style.errorMessageWrapper}>
          <span className={style.errorMessage}>
            { !!requiredField.length && `Fields ${requiredField} is required and must be selected` }
          </span>
          <span className={style.errorMessage}>
            {
              isFieldsAmountReachLimit && 'The file contains too many columns and does not meet our requirements.'
            }
          </span>
        </div>
        <div className={style.buttonsWrapper}>
          <div className={style.col1}>
            <Button onClick={hideModal} variant="outlined" sx={{ margin: '0px 5px' }}>
              Cancel
            </Button>
          </div>
          <div className={style.col2}>
            <Button
              disabled={!isUploadBtnAvailable || requiredField.length || isFieldsAmountReachLimit}
              onClick={uploadConfiguredLeadFile} 
              variant="contained" 
              sx={{ margin: '0px 5px' }}
            >
              Upload Leads
            </Button>
          </div>
        </div>
      </div>
    )
  );
}

export default UserUploadModal;
