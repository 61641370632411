import React, {
  useContext, useEffect, useState, 
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPencil } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { BsTrashFill } from 'react-icons/bs';
import Switch from '@mui/material/Switch';
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import { ModalContext } from '../../context';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';
import { AddDatafeedSettingModal } from './modals/AddDatafeedSettingModal';
import { UpdateDatafeedSettingModal } from './modals/UpdateDatafeedSettingModal';
import { getDatafeedSettings, toogleActiveDatafeed, deleteDatafeedSetting } from '../../redux/datafeeds/datafeedsActions';
    
export function DatafeedSettings() {
  const dispatch = useDispatch();
  const { showModal } = useContext(ModalContext);

  const datafeeds = useSelector((state) => state.datafeeds);
  const userData = useSelector((state) => state?.crmUser?.crmUserInfo);

  const isSuperUser = userData?.role?.name === 'SuperAdmin';

  const [loader, setLoader] = useState(false);

  useEffect(async () => {
    if (isSuperUser) {
      setLoader(true);
      await dispatch(getDatafeedSettings());
      setLoader(false);
    }
  }, [isSuperUser]);

  const handleChangeActiveStatus = ({ _id, isActive }) => {
    dispatch(toogleActiveDatafeed(_id, { isActive: !isActive }));
  };

  const deleteSetting = (id) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteDatafeedSetting(id));
      }
    });
  };

  const columns = [
    {
      name: 'Name',
      cell: ({ name }) => name,
    },
    {
      name: 'Actions',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: (row) => (
        <>
          <Switch checked={row.isActive} color="success" onChange={() => handleChangeActiveStatus({ _id: row._id, isActive: row.isActive })} />
          <button 
            type="button" 
            className="btn btn-success btn-sm me-1 p-1"
            onClick={() => showModal({
              bodyContent: <UpdateDatafeedSettingModal setting={row} />,
              headerContent: <h3>Add Setting</h3>, 
            })}
          >
            <FontAwesomeIcon icon={faPencil} />
          </button>
          <button type="button" className="btn btn-danger btn-sm me-1 p-1" onClick={() => deleteSetting(row._id)}>
            <BsTrashFill role="button" size={18} />
          </button>
        </>
      ),
    },
  ];

  return (
    loader ? <FullPageTransparentLoader /> : isSuperUser && (
    <div className="content-wrapper right-content-wrapper">
      <div className="content-box">
        <h3>Datafeed Settings</h3>

        <div className="action__btn-row">
          <button
            className="btn btn-default"
            type="button"
            style={{ 
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              backgroundColor: 'green',
            }}
            onClick={() => showModal({
              bodyContent: <AddDatafeedSettingModal />,
              headerContent: <h3>Add Setting</h3>, 
            })}
          >
            <FontAwesomeIcon
              icon={faPlus}
              size="xs"
            />
            Add
          </button>
        </div>
        <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
          <DataTable
            columns={columns}
            data={datafeeds}
            pagination
            highlightOnHover
            paginationRowsPerPageOptions={[25, 50, 200, 500]}
            persistTableHead
            theme="solarizedd"
          />
        </div>
                
      </div>
    </div>
    )
  );
}

