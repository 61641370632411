export const selectIsAffiliatesDashboardLoading = (state) => state.affiliatesDashboard.isLoading;
export const selectAffiliatesDashboardAnalysis = (state) => state.affiliatesDashboard.analysis;
export const selectAffiliatesDashboardFirstTimeDepositsData = (state) => state.affiliatesDashboard.firstTimeDeposits.data;
export const selectAffiliatesDashboardFirstTimeDepositsTotal = (state) => state.affiliatesDashboard.firstTimeDeposits.total;
export const selectAffiliatesDashboardFunnelNameData = (state) => state.affiliatesDashboard.funnelName.data;
export const selectAffiliatesDashboardFunnelNameTotal = (state) => state.affiliatesDashboard.funnelName.total;
export const selectAffiliatesDashboardCountryMapData = (state) => state.affiliatesDashboard.countryMapData;
export const selectAffiliatesDashboardStatisticByCountry = (state) => state.affiliatesDashboard.statisticByCountry;
export const selectAffiliatesDashboardTotalFtdByCountry = (state) => state.affiliatesDashboard.totalFtdByCountry;
export const selectAffiliatesDashboardDataByStatus = (state) => state.affiliatesDashboard.ftdDataByStatus;
export const selectAffiliatesDashboardColorDataByStatus = (state) => state.affiliatesDashboard.colorFtdDataByStatus;
export const selectAffiliatesDashboardAffiliateFilterOptions = (state) => state.affiliatesDashboard.affiliateFilterOptions;
export const selectAffiliatesDashboardFunnelNameFilterOptions = (state) => state.affiliatesDashboard.funnelNameFilterOptions;
