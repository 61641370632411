import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';
import { getUserActivity, exportUserActivityToFile } from '../../redux/clientActivity/clientActivityActions';
import { UserActivitySchema } from '../../DataTableSchemas/UserActivitySchema';
import { getActivityClientTypes } from '../../redux/activityClientType/activityClientTypeActions';


export function UserActivity() {
  const dispatch = useDispatch();
  const { id: userId } = useParams();

  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [sortDirection, setSortDirection] = useState(-1);
  const [isPaginationDT, setIsPaginationDT] = useState(false);
  const [activityTypeFilter, setActivityTypeFilter] = useState([]);
  const [userFilters, setUserFilters] = useState({});

  const { paginatedData, totalCount } = useSelector((state) => state?.clientActivity?.userActivity);
  
  const paginationStorageName = 'DataTable_userActivity/pagination';
  const filtersStorageName = 'DataTable_userActivity/filters'; 
  
  const handleExportClientActivity = async (fileType) => {
    await dispatch(exportUserActivityToFile({
      filteredItems: paginatedData,
      fileType, 
      clientTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }));
  };

  const setStoredPagination = () => {
    const userActivityRowsJSON = localStorage.getItem(paginationStorageName);
    if (userActivityRowsJSON) {
      const filterRows = JSON.parse(userActivityRowsJSON);
      setRowsPerPage(filterRows.rowsPerPage || 25);
      setPage(filterRows.page || 1);
    }

    setIsPaginationDT(true);
  };

  const setStoredFilterData = () => {
    const userActivityFiltersJSON = localStorage.getItem(filtersStorageName);
    if (userActivityFiltersJSON) {
      const filters = JSON.parse(userActivityFiltersJSON);
      setUserFilters(filters || {});
      setActivityTypeFilter(filters.activity || []);
      setSortDirection(filters.sortDirection || -1);
    }
  };

  useEffect(() => {
    setStoredFilterData();
    setStoredPagination();
    dispatch(getActivityClientTypes());
  }, []);

  const handlePageChange = (page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ rowsPerPage, page }));
    setPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ rowsPerPage: currentRowsPerPage, page }));
    setRowsPerPage(currentRowsPerPage);
  };

  useEffect(() => {
    async function fetchData() {
      if (isPaginationDT) {
        localStorage.setItem(filtersStorageName, JSON.stringify({ ...userFilters, sortDirection }));
        setLoader(true);
  
        await dispatch(getUserActivity({
          page, rowsPerPage, userId, query: { ...userFilters, sortDirection },
        }));
  
        setLoader(false);
      }
    };
    fetchData();
  }, [isPaginationDT, page, rowsPerPage, userFilters, sortDirection]);

  const columns = UserActivitySchema(
    activityTypeFilter,
    setActivityTypeFilter,
    userFilters,
    setUserFilters,
  );

  const handleSortTable = (_, sortDirectionColumn) => {
    setSortDirection(sortDirectionColumn === 'asc' ? 1 : -1);
  };

  return (
    loader ? <FullPageTransparentLoader />
      : (
        <div className="content-wrapper right-content-wrapper">
          <div className="content-box">
            <h3>User Activity Log</h3>
            <div className="d-flex">
              <Dropdown className="leads-download-dropdown ms-1">
                <Dropdown.Toggle variant="" className="btn-default" id="dropdown-basic">
                  Download
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <div className="import-csv-btn text-center mb-1">
                    <button type="button" className="btn btn-default" onClick={() => handleExportClientActivity('xlsx')}>Excel</button>
                  </div>
                  <div className="import-csv-btn text-center">
                    <button type="button" className="btn btn-default" onClick={() => handleExportClientActivity('csv')}>CSV</button>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="dashboard-tbl-wrapper custom-tbl-wrapper mt-3">
              {
                isPaginationDT
                  && (
                    <DataTable
                      columns={columns}
                      data={paginatedData}
                      fixedHeader
                      pagination
                      paginationServer
                      paginationTotalRows={totalCount}
                      paginationPerPage={rowsPerPage}
                      paginationRowsPerPageOptions={[25, 20, 100, 500]}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handleRowsPerPageChange}
                      paginationDefaultPage={page}
                      sortServer
                      onSort={handleSortTable}
                      persistTableHead
                      highlightOnHover
                      theme="solarizedd"
                      progressPending={loader}
                      progressComponent={<div className="datatable-loader__background" />}
                    />
                  )
                }
            </div>
          </div>
        </div>
      )
  );
};

