import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Select, MenuItem, Autocomplete, TextField, styled,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { getCfdSettings } from '../../redux/cfdSettings/cfdActions';
import { getAssetQuote } from '../../redux/assetQuote/assetQuoteActions';
import { openCFDOrder } from '../../redux/cfdOrder/cfdOrderActions';
import { getAccountOptionsByUserId } from '../../redux/cfdAccount/cfdAccountActions';
import { getFormattedTimeZoneOutput } from '../../helpers/helper';

export function OpenOrderModal({ hideModal }) {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.cfdSettings);
  const assetQuote = useSelector((state) => state.assetQuote);
  const accountOptions = useSelector((state) => state.cfdAccounts.options);
  const userData = useSelector((state) => state?.users?.user);

  const [formData, setFormData] = useState({
    symbolData: null,
    volumePercent: 0,
    tradeStartPrice: 0,
    takeProfitPrice: 0,
    stopLossPrice: 0,
    tradeType: null,
    accountId: null,
  });
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const options = settings.map((setting) => ({ value: setting._id, label: setting.label, brokerSymbol: setting.brokerSymbol }));
  const isBtnEnabled = !!(formData.symbolData && formData.volumePercent && formData.tradeType !== null && formData.accountId !== null);

  useEffect(() => {
    dispatch(getCfdSettings());
  }, []);

  useEffect(() => {
    dispatch(getAccountOptionsByUserId(userData._id || 'all'));
  }, [userData]);

  useEffect(() => {
    if (formData.symbolData !== null) {
      dispatch(getAssetQuote(formData.symbolData.brokerSymbol));
    }

    setFormData((prev) => ({ ...prev, tradeStartPrice: 0 }));
  }, [formData.symbolData]);

  useEffect(() => {
    if (formData.tradeType !== null) {
      setFormData((prev) => ({ ...prev, tradeStartPrice: formData.tradeType === 1 ? assetQuote.bid : assetQuote.ask }));
    }
  }, [formData.tradeType, assetQuote]);

  const CustomizedInput = styled(TextField)(() => ({
    '& .MuiInputBase-root': {
      paddingLeft: '16px',
      height: '45px',
      borderRadius: '4px',
      border: 'unset',
      outline: 'unset',
      '&.Mui-focused': {
        border: 'none',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
    '& .MuiSvgIcon-root': {
      color: '#fff',
    },
    '& .MuiIconButton-root': {
      border: '3px solid #fff)',
      borderRadius: '50%',
      height: '24px',
      width: '24px',
    },
    '& .MuiInputBase-root .MuiInputBase-input': {
      padding: 0,
      fontFamily: 'Arial',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '0.5px',
      textAlign: 'left',
      color: '#fff',
    },
  }));

  const handleNumberValue = (value) => value.replace(/[^\d.]/g, '').replace(/^0+(\d)/, '0$1');

  const submit = async () => {
    const data = { 
      ...formData, 
      settingId: formData.symbolData.value,
      accountId: formData.accountId.value,
      isTPSLSet: !!(formData.takeProfitPrice || formData.stopLossPrice),
    };

    setIsBtnLoading(true);
    await dispatch(openCFDOrder(data));
    setIsBtnLoading(false);
    hideModal();
  };

  return (
    <>
      <div className="row mb-3">
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Account</label>
          {/* <Select
            className="form-control"
            value={formData.accountId}
            onChange={({ target: { value } }) => setFormData((prev) => ({ ...prev, accountId: value }))}
            name="accountId"
            style={{ height: '40px' }}
          >
            <MenuItem value={null}>Select Account</MenuItem>
            { 
                accountOptions.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                ))
            }
          </Select> */}
          <Autocomplete
            sx={{ maxHeight: '45px', border: 'none' }}
            freeSolo
            placeholder="Select Account"
            options={accountOptions}
            onChange={(_, value) => setFormData((prev) => ({ ...prev, accountId: value }))}
            value={formData.accountId}
            renderInput={(params) => <CustomizedInput sx={{ border: '1px solid #2d3440', backgroundColor: '#2d3440', maxHeight: '45px' }} {...params} />}
            renderOption={(props, { label, value }) => (
              <MenuItem
                {...props}
                key={value}
                value={value}
              >
                {label}
              </MenuItem>
            )}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Symbol</label>
          <Autocomplete
            sx={{ maxHeight: '45px', border: 'none' }}
            freeSolo
            placeholder="Select Symbol"
            options={options}
            onChange={(_, value) => setFormData((prev) => ({ ...prev, symbolData: value }))}
            value={formData.symbolData}
            renderInput={(params) => <CustomizedInput sx={{ border: '1px solid #2d3440', backgroundColor: '#2d3440', maxHeight: '45px' }} {...params} />}
            renderOption={(props, { label, value }) => (
              <MenuItem
                {...props}
                key={value}
                value={value}
              >
                {label}
              </MenuItem>
            )}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Volume (0-100% of balance)</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Volume"
            aria-label=""
            aria-describedby=""
            value={formData.volumePercent}
            name="tradeStartPrice"
            onChange={({ target: { value } }) => setFormData((prev) => ({ ...prev, volumePercent: handleNumberValue(value) }))}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Take Profit</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Take Profit"
            aria-label=""
            aria-describedby=""
            name="takeProfitPrice"
            value={formData.takeProfitPrice}
            onChange={({ target: { value } }) => setFormData((prev) => ({ ...prev, takeProfitPrice: handleNumberValue(value) }))}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Stop Loss</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Stop Loss"
            aria-label=""
            aria-describedby=""
            name="stopLossPrice"
            value={formData.stopLossPrice}
            onChange={({ target: { value } }) => setFormData((prev) => ({ ...prev, stopLossPrice: handleNumberValue(value) }))}
          />
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Direction</label>
          <Select
            className="form-control"
            value={formData.tradeType}
            onChange={({ target: { value } }) => setFormData((prev) => ({ ...prev, tradeType: value }))}
            name="tradeType"
            style={{ height: '40px' }}
          >
            <MenuItem value={null} />
            <MenuItem value={1}>Buy</MenuItem>
            <MenuItem value={0}>Sell</MenuItem>
          </Select>
          <div>
            { `Time zone: ${getFormattedTimeZoneOutput()}` }
          </div>
        </div>
        <div className="form-group col-md-6 mt-2">
          <label className="control-label">Price Open</label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Price Open"
            aria-label=""
            aria-describedby=""
            value={formData.tradeStartPrice}
            name="tradeStartPrice"
            onChange={({ target: { value } }) => setFormData((prev) => ({ ...prev, stopLossPrice: handleNumberValue(value) }))}
          />
        </div>
      </div>
      <div>
        <button type="button" className="btn btn-default me-2" onClick={submit} disabled={!isBtnEnabled || isBtnLoading}>
          {
        !isBtnLoading ? 'Create' : <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
      }
        </button>
        <button type="button" className="btn btn-danger" onClick={hideModal}>Cancel</button>
      </div>
    </>
  );
}
