import React from 'react';
import { useSelector } from 'react-redux';
import { ModalFilters } from '../components';
import { formatDate } from './helper';


export const UserActivitySchema = (
  activityTypeFilter,
  setActivityTypeFilter,
  userFilters,
  setUserFilters,
) => {
  const activityTypes = useSelector((state) => state?.activityClientType?.activityClientTypes);

  const columns = [
    {
      name: 'Time',
      selector: ({ createdAt }) => (
        <span>{formatDate(new Date(createdAt))}</span>
      ),
      minWidth: '190px',
      sortable: true,
    },
    {
      name:  
      (
        <ModalFilters 
          data={activityTypes} 
          filters={activityTypeFilter} 
          setFilters={setActivityTypeFilter} 
          tabName="Activity" 
          searchParams={{ id: '_id', params: ['name'], optionsName: ['name'] }}
          usersFilters={userFilters}
          setUserFilters={setUserFilters}
        />
      ),
      minWidth: '190px',
      selector: ({ activityTypeName }) => activityTypeName,
      cell: ({ activityTypeName }) => {
        if (activityTypeName) {
          return (
            <span className="full-name-text">
              {activityTypeName}
            </span>
          );
        }
        return '-';
      },
      sortable: false,
    },
    {
      name: 'Information',
      selector: ({ information }) => information,
      cell: ({ information }) => {
        if (information) {
          return (
            <span className="full-name-text">
              {information}
            </span>
          );
        }
        return '-';
      },
      minWidth: '190px',
      sortable: false,
    },
    {
      name: 'IP',
      selector: ({ ip }) => ip,
      cell: ({ ip }) => {
        if (ip) {
          return (
            <span className="full-name-text">
              {ip}
            </span>
          );
        }
        return '-';
      },
      sortable: false,
    },
    {
      name: 'Browser',
      selector: ({ browser }) => browser,
      cell: ({ browser }) => {
        if (browser) {
          return (
            <span className="full-name-text">
              {browser}
            </span>
          );
        }
        return '-';
      },
      minWidth: '190px',
      sortable: false,
    },
  ];

  return columns;
};
