export function toFixed(num, fixed) {
  if (typeof num === 'undefined' || Number.isNaN(Number(num))) return 0;
  
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);
  
  return Number(num.toString().match(re)[0]);
}

export function calculatePercentage(part = 0, whole = 1) {
  if (whole !== 0) {
    return (part / whole) * 100;
  }

  return 0;
}
