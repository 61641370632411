/* eslint-disable react/no-unstable-nested-components */
import React, {
  useEffect, useContext, useState, useMemo, 
} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash, faPlus, faPencil, faExternalLink, 
} from '@fortawesome/free-solid-svg-icons';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2';
import CreateAccountModal from './CreateAccountModal';
import CreateSavingAccountModal from './CreateSavingAccountModal';
import EditAccountModal from './EditAccountModal';
import { deleteSavingAccount, deleteUserAccount, getCfdAccountsByQuery } from '../../../../redux/cfdAccount/cfdAccountActions';
import { ModalContext } from '../../../../context';
import { toFixed } from '../../../../helpers/utils';
import socket from '../../../../services/socket';

function Accounts({ userId }) {
  const dispatch = useDispatch();
  const { showModal, hideModal } = useContext(ModalContext);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tableLoading, setTableLoading] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [isPaginationDT, setIsPaginationDT] = useState(false);

  const { cfdAccounts = [], totalCount } = useSelector((state) => state.cfdAccounts?.allCfdAccounts);
  const { currentUserPermissions } = useSelector((state) => state.crmUser);
  const isUserCanAddCFDAccount = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('cfd_accounts_add');
  const isUserCanEditCFDAccount = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('cfd_accounts_edit');
  const isUserCanDeleteCFDAccount = currentUserPermissions && currentUserPermissions.length > 0 && currentUserPermissions.includes('cfd_accounts_delete');

  const orders = useMemo(() => {
    const allOrders = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const account of cfdAccounts) {
      if (account.orders && account.orders.length) allOrders.push(...account.orders);
    }

    return allOrders;
  }, [cfdAccounts]);

  useEffect(() => {
    setIsPaginationDT(true);
  }, []);

  useEffect(async () => {
    async function fetchData() {
      if (isPaginationDT) {
        setTableLoading(true);

        await dispatch(getCfdAccountsByQuery({ page, limit: rowsPerPage, query: { userId, isSaving: JSON.stringify([false, true]) } }));
    
        setTableLoading(false);
      }
    }
    fetchData();
  }, [page, rowsPerPage, userId, isPaginationDT]);

  useEffect(() => {
    const uniqueId = Date.now();

    if (orders.length) {
      socket.emit('getOpenOrderMarket', { orders, uniqueId });
    }

    socket.on(`receiveOpenOrderMarket&${uniqueId}`, (data) => {
      setOrderData(data);
    });

    return () => {
      socket.off(`receiveOpenOrderMarket&${uniqueId}`);
      setOrderData({});
    };
  }, [orders]);

  const deleteAccount = (id, isSaving = false) => {
    Swal.fire({
      title: 'Are you sure you want to Delete?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then((result) => {
        if (result.isConfirmed) {
          if (isSaving) {
            dispatch(deleteSavingAccount(id));
          } else {
            dispatch(deleteUserAccount(id));
          }
        }
      });
  };

  const calcSumByKey = ({ orders = [], key }) => {
    if (!orders.length) return 0;
    let margin = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const order of orders) {
      if (Object.keys(orderData).includes(order._id)) {
        margin += Number(orderData[order._id][key]);
      }
    }

    return toFixed(margin, 2);
  };


  const columns = [
    {
      name: 'ID',
      minWidth: '150px',
      selector: ({ customId }) => customId, 
    },
    {
      name: 'Type',
      minWidth: '150px',
      selector: ({ type, isSaving }) => (!isSaving ? type.name : '-'),
    },
    {
      name: 'Product',
      minWidth: '150px',
      selector: ({ isSaving }) => (isSaving ? 'Saving' : 'CFD'),
    },
    {
      name: 'Currency',
      minWidth: '150px',
      selector: ({ cfdAccountCurrency, currency }) => cfdAccountCurrency?.symbol || currency?.symbol || '-', 
      sortable: true,
    },
    {
      name: 'Balance',
      minWidth: '150px',
      selector: ({ balance }) => toFixed(balance, 2), 
      sortable: true,
    },
    {
      name: 'Credit',
      minWidth: '150px',
      selector: ({ credit }) => (credit ? toFixed(credit, 2) : '-'), 
      sortable: true,
    },
    {
      name: 'Leverage',
      minWidth: '180px',
      selector: ({ leverage }) => (leverage || '-'), 
      sortable: true,
    },
    {
      name: 'Lot Step',
      minWidth: '180px',
      selector: ({ lotStep }) => (lotStep || '-'), 
      sortable: true,
    },
    {
      name: 'Lot Size',
      minWidth: '180px',
      selector: ({ lotSize }) => (lotSize || '-'), 
      sortable: true,
    },
    {
      name: 'Commission%',
      minWidth: '180px',
      selector: ({ commission }) => (commission || '-'), 
      sortable: true,
    },
    {
      name: 'Swap Long%',
      minWidth: '180px',
      selector: ({ swapLong }) => (swapLong || '-'), 
      sortable: true,
    },
    {
      name: 'Swap Short%',
      minWidth: '180px',
      selector: ({ swapShort }) => (swapShort || '-'), 
      sortable: true,
    },
    {
      name: 'Margin',
      minWidth: '180px',
      selector: ({ orders }) => {
        const margin = calcSumByKey({ orders, key: 'margin' });

        return (<span style={margin !== 0 ? { color: margin > 0 ? 'green' : 'red' } : {}}>{margin}</span>);
      }, 
    },
    {
      name: 'Margin Level',
      minWidth: '180px',
      selector: ({ orders, balance, credit }) => {
        const margin = calcSumByKey({ orders, key: 'margin' });
        const pnl = calcSumByKey({ orders, key: 'pnl' });
        const equity = Number(balance) + pnl + Number(credit);
        const marginLevel = margin !== 0 ? (equity / margin) * 100 : 0;

        return (<span style={marginLevel !== 0 ? { color: marginLevel > 0 ? 'green' : 'red' } : {}}>{`${toFixed(marginLevel, 2)} %`}</span>);
      }, 
    },
    {
      name: 'Free Margin',
      minWidth: '180px',
      selector: ({ orders, balance, credit }) => {
        const margin = calcSumByKey({ orders, key: 'margin' });
        const pnl = calcSumByKey({ orders, key: 'pnl' });
        const equity = Number(balance) + pnl + Number(credit);
        const freeMargin = equity - margin;

        return (<span style={freeMargin !== 0 ? { color: freeMargin > 0 ? 'green' : 'red' } : {}}>{toFixed(freeMargin, 2)}</span>);
      }, 
    },
    {
      name: 'Is Active',
      minWidth: '180px',
      selector: ({ isActive, isSaving }) => (!isSaving ? (isActive ? 'Yes' : 'No') : '-'), 
      sortable: false,
    },
    {
      name: 'Enabled account',
      minWidth: '180px',
      selector: ({ isEnabled }) => (isEnabled !== undefined ? (isEnabled ? 'Yes' : 'No') : '-'), 
      sortable: false,
    },
    {
      name: 'Assigned To',
      minWidth: '150px',
      selector: ({ user }) => (
        <div className="cfdSccounts-row_ClientWrapper">
          { 
          (user && user.manager) ? (
            <Link to={`/edit-admin/${user.manager._id}`} target="blank" className="text-white">
              <FontAwesomeIcon icon={faExternalLink} size="xs" />
              <span 
                className="cfdSccounts-row_fullName"
              >
                {`${user.manager.firstName} ${user.manager.lastName}`}
              </span>
            </Link>
          ) : (<span>-</span>) 
        }
        </div> 
      ), 
    },
    {
      name: 'Status',
      minWidth: '150px',
      cell: ({ user }) => (user && user.status ? user.status.name : '-'),
    },
    {
      name: 'Actions',
      minWidth: '200px',
      cell: (row) => (
        <div style={{ display: 'flex' }}>
          {
            !row.isSaving && isUserCanEditCFDAccount && (
              <button
                type="button"
                className="btn btn-success btn-sm me-1 p-1"
                onClick={() => showModal({
                  headerContent: <h3>{`Edit Account #${row.customId}`}</h3>,
                  bodyContent: <EditAccountModal row={row} />,
                })}
              >
                <FontAwesomeIcon icon={faPencil} className="header-icon" />
              </button>
            )
          }
          {isUserCanDeleteCFDAccount && (
            <button
              type="button"
              className="btn btn-danger btn-sm me-1 p-1"
              onClick={() => deleteAccount(row._id, row.isSaving)}
            >
              <FontAwesomeIcon icon={faTrash} className="header-icon" />
            </button>
          )}
        </div>

      ),
    },
  ];

  return (
    <div>
      <div className="action__btn-row">
        {isUserCanAddCFDAccount && (
          <button
            type="button"
            className="btn btn-default m-1 icon-btn btn-bg-green"
            onClick={() => showModal({
              headerContent: <h3>ADD ACCOUNT</h3>,
              bodyContent: <CreateAccountModal closeModal={hideModal} userId={userId} />,
            })}
          >
            <FontAwesomeIcon icon={faPlus} size="sm" />
            Add Account
          </button>
        )}
        

        <button
          type="button"
          className="btn btn-default m-1 icon-btn btn-bg-green"
          onClick={() => showModal({
            headerContent: <h3>ADD SAVING ACCOUNT</h3>,
            bodyContent: <CreateSavingAccountModal closeModal={hideModal} userId={userId} />,
          })}
        >
          <FontAwesomeIcon icon={faPlus} size="sm" />
          Add Saving Account
        </button>
      </div>
      <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
        { 
          isPaginationDT && (
            <DataTable
              columns={columns}
              data={cfdAccounts}
              theme="solarizedd"
              fixedHeader
              pagination
              paginationServer
              paginationPerPage={rowsPerPage}
              paginationTotalRows={totalCount}
              paginationRowsPerPageOptions={[5, 10, 15]}
              onChangePage={(page) => setPage(page)}
              onChangeRowsPerPage={(currentRowsPerPage) => setRowsPerPage(currentRowsPerPage)}
              paginationDefaultPage={page}
              persistTableHead
              highlightOnHover
              progressPending={tableLoading}
              progressComponent={<div className="datatable-loader__background" />}
            />
          )
        }
      </div>
    </div>
  );
}

export default Accounts;
