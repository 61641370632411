import React, {
  useState, useEffect, useRef, useContext, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { Dropdown } from 'react-bootstrap';
import Swal from 'sweetalert2';
import {
  faCheck, faColumns, faTimes, faRefresh, faPlus, faEdit,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCFDAccountTypes } from '../../redux/cfdAccountType/cfdAccountTypeActions';
import {
  getCfdAccountsByQuery, exportFilteredDataToFile, deleteUserAccount, deleteSavingAccount, 
} from '../../redux/cfdAccount/cfdAccountActions';
import { showAllCurrencies } from '../../redux/currency/currencyActions';
import {
  getSubCRMUsers, createCRMFilter, deleteCRMFilter, updateCRMFilter, 
} from '../../redux/crmUser/crmUserActions';
import { getSalesStatuses } from '../../redux/salesStatus/salesStatusActions';
import { ModalContext } from '../../context';
import FullPageTransparentLoader from '../FullPageTransparentLoader/fullPageTransparentLoader';
import { DownloadFile } from '../../components/DownloadFile';
import { DatatableFilters } from '../../components/DatatableFilters/DatatableFilters';
import { cfdAccountsColumns } from '../../columnsDefaultConfig/cfdAccountsColumns';
import { CfdAccountsSchema } from '../../DataTableSchemas/cfdAccountsSchema/CfdAccountsSchema';
import { useDebounce } from '../../hooks/useDebounce';
import { MassOpenOrdersModal } from './MassOpenOrdersModal';
import { MassEditAccountsModal } from './MassEditAccountsModal';
import socket from '../../services/socket';

function CfdAccounts() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { showModal, hideModal } = useContext(ModalContext);

  const paginationStorageName = 'DataTable_cfdAccounts/pagination';
  const filtersStorageName = 'DataTable_cfdAccounts/filters'; 
  const filterIdStorageName = 'DataTable_cfdAccounts/filters_id'; 
  const columnsStorageName = 'DataTable_cfdAccounts/columns';
  const paginationStorageJSON = localStorage.getItem(paginationStorageName);
  const cfdStorageFiltersJSON = localStorage.getItem(filtersStorageName);
  const cfdStorageIdJSON = localStorage.getItem(filterIdStorageName);
  const cfdColumnsJSON = localStorage.getItem(columnsStorageName);
  const storageUserId = localStorage.getItem('userId');

  const { cfdAccounts = [], totalCount } = useSelector((state) => state.cfdAccounts?.allCfdAccounts);
  const currencies = useSelector((state) => state.currency?.currencies?.allCurrencies);
  const crmFilters = useSelector((state) => state.crmUser?.crmUserInfo?.filters);
  const permissionName = useSelector((state) => state.crmUser?.currentUserPermissions);
  const accountTypes = useSelector((state) => state.cfdAccountTypes);
  const crmUsers = useSelector((state) => state.crmUser?.crmUsers);
  const salesStatuses = useSelector((state) => state?.salesStatus?.salesStatuses);

  const [loader, setLoader] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [columnConfig, setColumnConfig] = useState(cfdAccountsColumns);
  const [isPaginationDT, setIsPaginationDT] = useState(false);

  const datatableFiltersRef = useRef(null);

  const orders = useMemo(() => {
    const allOrders = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const account of cfdAccounts) {
      if (account.orders && account.orders.length) allOrders.push(...account.orders);
    }

    return allOrders;
  }, [cfdAccounts]);


  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(1);
  const [customIdFilter, setCustomIdFilter] = useState();
  const [fullNameFilter, setFullNameFilter] = useState();
  const [orderData, setOrderData] = useState({});
  const [amountRange, setAmountRange] = useState(null);
  const [creditRange, setCreditRange] = useState(null);
  const [balanceRange, setBalanceRange] = useState(null);
  const [assignedToFilter, setAssignedToFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [assetFilter, setAssetFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState([]);
  const [createdAtFilter, setCreatedAtFilter] = useState([]);
  const [selectedAccountRows, setSelectedAccountRows] = useState([]);
  const [actionName, setActionName] = useState(null);
  const [cfdQuery, setCfdQuery] = useState({});
  const [toggleCleared, setToggleCleared] = useState(false);
  const [inputFieldError, setInputFieldError] = useState({
    fullNameIsNotValid: false,
    errorMessage: '',
  });

  const debounceCallback = ({ value, key }) => {
    if ((value.length >= 3 || value.length === 0 || Array.isArray(value)) && cfdQuery[key] !== value) {
      setCfdQuery((prev) => ({ ...prev, [key]: value }));
    }
  };

  const debounceIDCallback = ({ value, key }) => {
    if ((value.length >= 1 || value.length === 0 || Array.isArray(value)) && cfdQuery[key] !== value) {
      setCfdQuery((prev) => ({ ...prev, [key]: value }));
    }
  };

  useDebounce(customIdFilter, 1000, (value) => debounceIDCallback({ value, key: 'id' }));
  useDebounce(fullNameFilter, 1000, (value) => debounceCallback({ value, key: 'fullName' }));
  useDebounce(amountRange, 1000, (value) => debounceCallback({ value, key: 'amountRange' }));
  useDebounce(creditRange, 1000, (value) => debounceCallback({ value, key: 'creditRange' }));
  useDebounce(balanceRange, 1000, (value) => debounceCallback({ value, key: 'balanceRange' }));

  const setDebounceAmountRange = (type, value, amountRangeValue, setState) => {
    if (amountRangeValue && amountRangeValue.length) {
      const [minValue, maxValue] = amountRangeValue;
      if (type === 'min') {
        setState([Number(value), maxValue]);
      }
      if (type === 'max') {
        setState([minValue, Number(value)]);
      }
      if (type === 'slider') {
        setState(value);
      }
    }
  };

  const toastError = (title) => {
    toast.error(title, {
      autoClose: 1000,
    });
  };

  const setStoredPagination = () => {
    if (paginationStorageJSON) {
      const filterRows = JSON.parse(paginationStorageJSON);
      setRowsPerPage(filterRows.limit || 25);
      setPage(filterRows.page || 1);
    }

    setIsPaginationDT(true);
  };

  const setStoredColumnsData = () => {
    if (cfdColumnsJSON) {
      const columns = JSON.parse(cfdColumnsJSON);

      setColumnConfig(columns);
    } else {
      localStorage.setItem(columnsStorageName, JSON.stringify(columnConfig));
    }
  };

  const setStoredFilterData = () => {
    if (cfdStorageFiltersJSON) {
      const filters = JSON.parse(cfdStorageFiltersJSON);
      setCfdQuery(filters || {});

      setCustomIdFilter(filters.id || null);
      setFullNameFilter(filters.fullName || null);
      setAmountRange(filters.amountRange || null);
      setBalanceRange(filters.balanceRange || null);
      setCreditRange(filters.creditRange || null);
      setAssetFilter(filters.currency || []);
      setTypeFilter(filters.type || []);
      setAssignedToFilter(filters['assigned to'] || []);
      setStatusFilter(filters.status || []);
      setCreatedAtFilter(filters.created || []);
    }
  };

  const getAllStoredData = () => {
    setStoredColumnsData();
    setStoredFilterData();
    setStoredPagination();
  };

  useEffect(() => {
    const { roleId, _id: uid } = JSON.parse(localStorage.getItem('user'));
    getAllStoredData();

    Promise.allSettled([
      dispatch(getSalesStatuses()),
      dispatch(showAllCurrencies()),
      dispatch(getCFDAccountTypes()),
      dispatch(getSubCRMUsers(roleId, uid)),
    ]);
  }, []);

  useEffect(() => {
    const uniqueId = Date.now();

    if (orders.length) {
      socket.emit('getOpenOrderMarket', { orders, uniqueId });
    }

    socket.on(`receiveOpenOrderMarket&${uniqueId}`, (data) => {
      setOrderData(data);
    });

    return () => {
      socket.off(`receiveOpenOrderMarket&${uniqueId}`);
      setOrderData({});
    };
  }, [orders]);

  const toggleColumn = (name) => {    
    const updatedColumns = columnConfig.map((column) => {
      if (column.name === name) {
        return { ...column, selected: !column.selected };
      }

      return column;
    });

    setColumnConfig(updatedColumns);
    localStorage.setItem(columnsStorageName, JSON.stringify(updatedColumns));
  };

  const handleExportTransactions = async (fileType) => {
    const columns = columnConfig.filter((obj) => obj.selected && obj.field);

    if (cfdAccounts.length && columns.length) {
      setLoader(true);
      toast.success('Data export in progress. Please wait while we prepare the file.', {
        autoClose: 2000,
      });
      await dispatch(exportFilteredDataToFile(cfdAccounts, columns, fileType, orderData)); 
      setLoader(false);
    } else {
      toastError('There is nothing to download.');
    }
  };

  const handleClear = () => {
    setCustomIdFilter(null);
    setFullNameFilter(null);
    setAmountRange(null);
    setBalanceRange(null);
    setCreditRange(null);
    setAssetFilter([]);
    setTypeFilter([]);
    setAssignedToFilter([]);
    setStatusFilter([]);
    setCreatedAtFilter([]);
    setAmountRange(null);
    setCfdQuery({});
    setPage(1);
    setRowsPerPage(25);
    localStorage.removeItem(filtersStorageName);
    datatableFiltersRef.current.clearDrodownName();
  };

  const refresh = async () => {
    setTableLoading(true);
    await getAllStoredData();
    setTableLoading(false);
  };

  const handlePageChange = (page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: rowsPerPage, page }));
    setPage(page);
  };

  const handleRowsPerPageChange = (currentRowsPerPage, page) => {
    localStorage.setItem(paginationStorageName, JSON.stringify({ limit: currentRowsPerPage, page }));
    setRowsPerPage(currentRowsPerPage);
  };

  useEffect(async () => {
    if (isPaginationDT) {
      localStorage.setItem(filtersStorageName, JSON.stringify(cfdQuery));
      setTableLoading(true);

      await dispatch(getCfdAccountsByQuery({ page, limit: rowsPerPage, query: { ...cfdQuery, isSaving: JSON.stringify([false]) } }));

      setTableLoading(false);
    }
  }, [page, rowsPerPage, isPaginationDT, cfdQuery]);

  const setCRMFilters = (filter) => {
    const {
      fullName, amountRange, currency, isUSDRange, uid, status, type, assignedTo, creditRange, timeOptionIds,
    } = filter;

    setAssignedToFilter(filter['assigned to'] || []);
    setAssetFilter(currency || []);
    setTypeFilter(type || []);
    setStatusFilter(status || []);
    setCustomIdFilter(uid || '');
    setFullNameFilter(fullName || '');
    setCreditRange(creditRange || [0, 1000000]);
    setAmountRange(amountRange || [0, 1000000]);
    setBalanceRange(isUSDRange || [0, 1000000]);
    setCreatedAtFilter(timeOptionIds || []);

    setCfdQuery({
      id: uid,
      fullName,
      creditRange,
      amountRange,
      currency,
      type,
      balanceRange: isUSDRange,
      'assigned to': assignedTo,
      status,
      created: timeOptionIds,
    });
    localStorage.setItem(filterIdStorageName, JSON.stringify(filter._id));
  };

  const createUserCRMFilter = async (name) => {
    const crmUserId = JSON.parse(storageUserId);
    const filters = JSON.parse(cfdStorageFiltersJSON);
    const data = {
      name,
      crmUserId,
      pathname,
      uid: filters.id || '',
      fullName: filters.fullName || '',
      creditRange: filters.creditRange?.length ? filters.creditRange : [0, 1000000],
      amountRange: filters.amountRange?.length ? filters.amountRange : [0, 1000000],
      currency: filters.currency || [],
      type: filters.type || [],
      isUSDRange: filters.balanceRange?.length ? filters.balanceRange : [0, 1000000],
      assignedTo: filters['assigned to'] || [],
      status: filters.status || [],
      timeOptionIds: filters.created || [],
    };

    const res = await dispatch(createCRMFilter(data));

    if (res && res.data && res.data.filter) {
      localStorage.setItem(filterIdStorageName, JSON.stringify(res.data.filter._id));
      datatableFiltersRef.current.handleAfterCreate();
    }
  };

  const deleteUserCRMFilter = async () => {
    if (cfdStorageIdJSON) {
      const id = JSON.parse(cfdStorageIdJSON);

      await dispatch(deleteCRMFilter(id));
      handleClear();
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const updateUserCRMFilter = async () => {
    if (cfdStorageIdJSON) {
      const id = JSON.parse(cfdStorageIdJSON);
      const filters = JSON.parse(cfdStorageFiltersJSON);
      const data = {
        uid: filters.id || '',
        fullName: filters.fullName || '',
        creditRange: filters.creditRange?.length ? filters.creditRange : [0, 1000000],
        amountRange: filters.amountRange?.length ? filters.amountRange : [0, 1000000],
        currency: filters.currency || [],
        type: filters.type || [],
        isUSDRange: filters.balanceRange?.length ? filters.balanceRange : [0, 1000000],
        assignedTo: filters['assigned to'] || [],
        status: filters.status || [],
        timeOptionIds: filters.created || [],
      };

      dispatch(updateCRMFilter(id, data));
    } else {
      toastError('Select atleast one filter to complete this action.');
    }
  };

  const handleDeleteCfdAccounts = async ({ _id, isSaving }) => {
    Swal.fire({
      title: !isSaving ? 'Are you sure you want to Delete CFD Account?' : 'Are you sure you want to Delete Saving Account?',
      html: '',
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonColor: '#053d71',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    })
      .then((result) => {
        if (result.isConfirmed) {
          if (isSaving) {
            dispatch(deleteSavingAccount(_id));
          } else {
            dispatch(deleteUserAccount(_id));
          }
        }
      });
  };

  const handleAccountTableSelect = async ({ selectedRows }) => {
    setSelectedAccountRows(selectedRows);
  };

  const clearRows = () => {
    setToggleCleared((prev) => (!prev));
    setSelectedAccountRows([]);
  };

  const handleOpenMassOrderTable = () => {
    showModal({
      headerContent: <h3>Mass Open Orders</h3>,
      bodyContent: <MassOpenOrdersModal accounts={selectedAccountRows} hideModal={hideModal} clearRows={clearRows} />,
    });
  };

  const handleEditMassAccountTable = () => {
    showModal({
      headerContent: <h3>Mass Edit Accounts</h3>,
      bodyContent: <MassEditAccountsModal accounts={selectedAccountRows} hideModal={hideModal} clearRows={clearRows} />,
    });
  };

  const getComponentByActionName = (actionName) => {
    const cmpToActionName = {
      'Mass Open Orders': (
        <button 
          disabled={!selectedAccountRows.length}
          type="button"
          className="btn btn-default ms-1"
          onClick={handleOpenMassOrderTable}
          style={{ 
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <FontAwesomeIcon icon={faPlus} size="sm" />
          Mass Open Orders
        </button>
      ),
      'Mass Edit Accounts': (
        <button 
          disabled={!selectedAccountRows.length}
          type="button"
          className="btn btn-default ms-1"
          onClick={handleEditMassAccountTable}
          style={{ 
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
        >
          <FontAwesomeIcon icon={faEdit} size="sm" />
          Mass Edit Accounts
        </button>
      ),
    };

    return cmpToActionName[actionName] ?? null;
  };

  const columns = CfdAccountsSchema(
    columnConfig,
    amountRange,
    setAmountRange,
    cfdQuery,
    setCfdQuery,
    currencies,
    assetFilter,
    setAssetFilter,
    creditRange,
    setCreditRange,
    permissionName,
    balanceRange,
    setBalanceRange,
    setDebounceAmountRange,
    customIdFilter, 
    setCustomIdFilter,
    fullNameFilter, 
    setFullNameFilter,
    handleDeleteCfdAccounts,
    setInputFieldError,
    inputFieldError,
    accountTypes,
    crmUsers,
    assignedToFilter,
    setAssignedToFilter,
    salesStatuses,
    statusFilter,
    setStatusFilter,
    createdAtFilter,
    setCreatedAtFilter,
    orderData,
  );

  return (
    loader ? (
      <FullPageTransparentLoader />
    ) : (
      <div className="content-wrapper right-content-wrapper">
        <div className="content-box">
          <h3>CFD Accounts</h3>
          <div className="action__btn-row">
            {crmFilters && (
            <DatatableFilters 
              ref={datatableFiltersRef}
              filters={crmFilters}
              setFilters={setCRMFilters}
              createFilter={createUserCRMFilter}
              deleteFilter={deleteUserCRMFilter}
              updateFilter={updateUserCRMFilter}
              storageKey={filterIdStorageName}
              pathname={pathname}
            />
            )} 
            <DownloadFile handleExport={handleExportTransactions} />
            <Dropdown className="leads-columns-dropdown ms-1">
              <Dropdown.Toggle 
                variant="" 
                className="btn-default"
                style={{ 
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                }}
              >
                <FontAwesomeIcon
                  icon={faColumns}
                  size="xs"
                />
                Columns
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <ul className="leads-columns-list">
                  {columnConfig.map((column) => (
                    <li className="leads-columns-option" key={column.name} onClick={() => toggleColumn(column.name)}>
                      {column.selected ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          color="#6E7F95"
                        />
                      ) : null}
                      <span className="leads-columns-option__name">{column.name}</span>
                    </li>
                  ))}
                </ul>
              </Dropdown.Menu>
            </Dropdown> 
            <button 
              type="button"
              className="btn btn-default ms-1"
              onClick={handleClear}
              style={{ 
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <FontAwesomeIcon icon={faTimes} size="sm" />
              Clear
            </button>
            <button 
              type="button"
              className="btn btn-default ms-1"
              onClick={refresh}
              style={{ 
                display: 'flex',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <FontAwesomeIcon icon={faRefresh} size="sm" />
              Refresh
            </button>
            <div style={{
              display: 'flex', alignItems: 'center', color: '#fff', marginLeft: '15px', 
            }}
            >
              <div>{`Selected ${selectedAccountRows.length} records`}</div>
              <Dropdown className="dt-actions__dropdown ms-1">
                <Dropdown.Toggle variant="" className="btn-default">{actionName || 'Select Action'}</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setActionName('Mass Open Orders')}>Mass Open Orders</Dropdown.Item>
                  <Dropdown.Item onClick={() => setActionName('Mass Edit Accounts')}>Mass Edit Accounts</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              { getComponentByActionName(actionName) }
            </div>
          </div>
          <div className="dashboard-tbl-wrapper custom-tbl-wrapper">
            {isPaginationDT && (
            <DataTable
              columns={columns}
              data={cfdAccounts}
              fixedHeader
              pagination
              paginationServer
              paginationPerPage={rowsPerPage}
              paginationTotalRows={totalCount}
              paginationRowsPerPageOptions={[25, 50, 100, 500]}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              onSelectedRowsChange={handleAccountTableSelect}
              paginationDefaultPage={page}
              clearSelectedRows={toggleCleared}
              selectableRows
              persistTableHead
              highlightOnHover
              theme="solarizedd"
              progressPending={tableLoading}
              progressComponent={<div className="datatable-loader__background" />}
            />
            )}
          </div>
        </div>
      </div>
    )
  );
};
export default CfdAccounts;
